import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useStyles } from './confirmationTitleRow.css'

interface Props {
    title: string
    disabled: boolean
    onEdit: () => void
}

const ConfirmationTitleRow: React.FC<Props> = ({ title, disabled, onEdit }) => {
    const classes = useStyles()
    return (
        <div className={classes.row}>
            <Typography variant="h1" color="textSecondary" className={classes.title}>
                <Trans i18nKey={title} />
            </Typography>
            <Button variant="contained" color="primary" disabled={disabled} onClick={onEdit}>
                <Trans i18nKey="actions.edit" />
            </Button>
        </div>
    )
}

export default ConfirmationTitleRow
