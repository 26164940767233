import actions from '../../../core/store/actions'
import {
    RestaurantOwnerSignInByEmailRequest,
    RestaurantOwnerSignInResponse,
} from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'

export const useRestaurantOwnerSignInByEmail = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const signInRestaurantOwnerByEmail = (
        payload: RestaurantOwnerSignInByEmailRequest,
    ): Promise<RestaurantOwnerSignInResponse> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerSignInByEmail(payload)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.restaurantOwnerSignIn(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                dispatch(actions.restaurantOwnerSignOut())
                throw generateError(error)
            })
    }

    return {
        signInRestaurantOwnerByEmail,
        inProgress,
    }
}
