import React from 'react'
import {
    Button,
    FormControl,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core'
import { Trans } from 'react-i18next'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import moment from 'moment'
import { useStyles } from './card.css'
import clsx from 'clsx'

interface Props {
    email: string
    establishments: Array<EstablishmentModel>
    onChooseEstablishment: (establishment: EstablishmentModel) => void
    onCancel: () => void
}

const EstablishmentSelectCard: React.FC<Props> = ({
    establishments,
    email,
    onChooseEstablishment,
    onCancel,
}) => {
    const classes = useStyles()
    return (
        <>
            <Typography color="primary" className={clsx(classes.title)}>
                <Trans i18nKey="home.establishment-select.title" />
            </Typography>
            <Typography color="textPrimary">
                <Trans
                    i18nKey={
                        establishments.length > 1
                            ? 'home.establishment-select.messageMultiple'
                            : 'home.establishment-select.message'
                    }
                />
            </Typography>
            <TextField
                margin="normal"
                fullWidth
                value={email}
                name="email"
                label={<Trans i18nKey="form.email" />}
                autoComplete="email"
                type="email"
                required
                disabled
                InputLabelProps={{ shrink: true }}
            />
            <FormControl margin="normal" fullWidth>
                <Typography>
                    <Trans i18nKey="home.establishment-select.select" />
                </Typography>
                <List>
                    {establishments.map((establishment) => (
                        <ListItem
                            key={establishment.id}
                            divider
                            button
                            onClick={() => onChooseEstablishment(establishment)}
                        >
                            <ListItemText
                                primary={establishment.name}
                                secondary={
                                    <Trans
                                        i18nKey={'home.establishment-select.createdAt'}
                                        values={{ date: moment(establishment.createdAt).toDate() }}
                                    />
                                }
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => onChooseEstablishment(establishment)}
                                >
                                    <Trans i18nKey="actions.chooseEstablishment" />
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                    <ListItem divider button onClick={() => onCancel()}>
                        <ListItemText
                            classes={{
                                root: classes.cancel,
                            }}
                        >
                            <Button variant="outlined" color="primary" onClick={() => onCancel()}>
                                <Trans i18nKey="actions.chooseEstablishmentCancel" />
                            </Button>
                        </ListItemText>
                    </ListItem>
                </List>
            </FormControl>
        </>
    )
}

export default EstablishmentSelectCard
