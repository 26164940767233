import Loader from 'components/common/loader'
import ShareLocation from 'components/views/establishment/components/shareLocation'
import EstablishmentPhotoView from 'components/views/establishment/establishmentPhotoView'
import withAuthenticateLayout, { WithAuthenticateLayoutProps } from 'containers/hoc/withAuthenticateLayout'
import React from 'react'
import Advantage from '../../components/views/establishment/components/advantages'

interface Props extends WithAuthenticateLayoutProps {}

const Menu: React.FC<Props> = ({ establishment }) => {
    return (
        <div>
            {establishment ? (
                <>
                    <EstablishmentPhotoView establishment={establishment} />
                    <ShareLocation establishment={establishment} title="establishment.shared.on" />
                    <Advantage />
                </>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default withAuthenticateLayout(Menu)
