// External libs
import React from 'react'
// Theme
import CircularProgress from '@material-ui/core/CircularProgress'
// Style
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Logo from 'components/common/logo'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        logo: {
            'padding': theme.spacing(6, 2),
            'maxWidth': '100%',
            '& img': {
                height: '40vh',
                marginBottom: 0,
            },
        },
        indicator: {
            padding: theme.spacing(2),
        },
    }),
)

interface Props {
    withLogo?: boolean
}
const Loader: React.FC<Props> = ({ withLogo = true }) => {
    const classes = useStyles()
    return (
        <div className={classes.main}>
            {withLogo && (
                <div className={classes.logo}>
                    <Logo />
                </div>
            )}
            <div className={classes.indicator}>
                <CircularProgress size="4rem" />
            </div>
        </div>
    )
}

export default Loader
