import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
    AddressModel,
    EstablishmentModel,
    LocationModel,
    useEstablishmentCreate,
} from '@sugg-gestion/react-onvaauresto'
import EstablishmentAddressForm, {
    EstablishmentAddressFormState,
} from 'components/forms/establishmentAddressForm'
import EstablishmentDetailsForm, {
    EstablishmentDetailsFormState,
} from 'components/forms/establishmentDetailsForm'
import EstablishmentPhotoForm, { EstablishmentPhotoFormState } from 'components/forms/establishmentPhotoForm'
import Step from 'components/views/establishment/step'
import withAuthenticateLayout, { WithAuthenticateLayoutProps } from 'containers/hoc/withAuthenticateLayout'
import { Image } from 'core/services/images/useImages'
import { CountryCode } from 'libphonenumber-js'
import React, { useState } from 'react'
import EditDialog from '../../components/dialogs/editDialog'
import Advantage from '../../components/views/establishment/components/advantages'
import ShareLocation from '../../components/views/establishment/components/shareLocation'
import Confirmation from '../../components/views/establishment/confirmation'
import Congratulation from '../../components/views/establishment/congratulation'
import EstablishmentFormContainer from '../../components/views/establishment/establishmentFormContainer'
import { useApiErrors } from '../../core/services/onvaauresto/useApiErrors'
import { usePhoneNumber } from '../../core/services/phone/usePhoneNumber'

export interface EstablishmentFormState {
    name: string
    address: AddressModel
    location: LocationModel
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
    url?: string
    image?: Image
    isFacebookShared: boolean
    remplisVert: boolean
    remplisVertComment?: string
}

interface Props extends WithAuthenticateLayoutProps {
    token: string
    establishment?: EstablishmentModel
}

const Establishment: React.FC<Props> = ({ token, establishment, restaurantOwner }) => {
    const [step, setStep] = useState(0)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { displayPhone } = usePhoneNumber()
    const [establishmentState, setEstablishmentState] = useState<EstablishmentFormState>({
        name: '',
        phone: displayPhone(restaurantOwner.phone, restaurantOwner.country as CountryCode),
        email: restaurantOwner.email,
        facebookPage: undefined,
        address: {
            line1: '',
            city: '',
            zipCode: '',
            country: restaurantOwner.country ?? 'FR',
        },
        location: {
            latitude: 46.7111,
            longitude: 1.7191,
        },
        url: undefined,
        isFacebookShared: true,
        remplisVert: false,
    })
    const [editDialog, setEditDialog] = useState<number | undefined>()

    const { establishmentCreate, inProgress } = useEstablishmentCreate()
    const { displayError } = useApiErrors()

    const handleCloseEditDialog = () => {
        setEditDialog(undefined)
    }

    const handleIgnore = () => {
        setStep(step + 1)
        window.scrollTo({ top: 0 })
    }

    const handleEstablishmentUpdate = (
        values: EstablishmentDetailsFormState | EstablishmentAddressFormState | EstablishmentPhotoFormState,
    ) => {
        setEstablishmentState({
            ...establishmentState,
            ...values,
        })
        if (editDialog === undefined) {
            setStep(step + 1)
            window.scrollTo({ top: 0 })
        } else {
            handleCloseEditDialog()
        }
    }

    const handleEstablishmentCreate = () => {
        establishmentCreate(token, {
            ...establishmentState,
            image: establishmentState.image?.file,
            remplisVertComment: establishmentState.remplisVert
                ? establishmentState.remplisVertComment
                : undefined,
        })
            .then(() => {
                setStep(step + 1)
                window.scrollTo({ top: 0 })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const formSteps: Array<(onBack?: () => void) => React.ReactNode> = [
        (onBack) => (
            <EstablishmentDetailsForm
                inProgress={false}
                initialValues={{
                    ...establishmentState,
                }}
                onSubmit={handleEstablishmentUpdate}
                onBack={onBack}
                country={(establishmentState.address.country as CountryCode) ?? 'FR'}
            />
        ),
        (onBack) => (
            <EstablishmentAddressForm
                inProgress={false}
                initialValues={{
                    ...establishmentState,
                }}
                onSubmit={handleEstablishmentUpdate}
                onBack={onBack}
                isMobile={editDialog !== undefined ? true : isMobile}
            />
        ),
        (onBack) => (
            <>
                <EstablishmentPhotoForm
                    inProgress={false}
                    initialValues={{
                        ...establishmentState,
                    }}
                    onSubmit={handleEstablishmentUpdate}
                    onBack={onBack}
                    isPhotoShared={false}
                    showPhotoShare={false}
                    forceFacebook
                    onIgnore={editDialog === undefined ? handleIgnore : undefined}
                />

                <ShareLocation establishment={establishment} withoutEstablishment />
                <Advantage />
            </>
        ),
        () => (
            <Confirmation
                state={establishmentState}
                onSubmit={handleEstablishmentCreate}
                inProgress={inProgress}
                onEdit={(editStep) => setEditDialog(editStep)}
            />
        ),
        () => <Congratulation establishment={establishment} />,
    ]

    const displayStep = () => {
        let onBack = undefined
        if (step > 0 && step < 3) {
            onBack = () => {
                setStep(step - 1)
            }
        }
        return formSteps[step](onBack)
    }

    return (
        <EstablishmentFormContainer>
            <Step step={step} steps={formSteps.length} />
            {displayStep()}
            {editDialog !== undefined && (
                <EditDialog onClose={handleCloseEditDialog}>
                    {formSteps[editDialog](handleCloseEditDialog)}
                </EditDialog>
            )}
        </EstablishmentFormContainer>
    )
}

export default withAuthenticateLayout(Establishment)
