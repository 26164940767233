import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'

export const useRestaurantOwnerResend = () => {
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnerResend = (token: string): Promise<void> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerResend(token)
            .then(() => {
                setInProgress(false)
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        restaurantOwnerResend,
        inProgress,
    }
}
