import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        resend: {
            'display': 'flex',
            'flexDirection': 'row',
            'alignItems': 'center',
            'marginTop': theme.spacing(2),
            '& p': {
                flexGrow: 1,
                textAlign: 'right',
                fontSize: '0.9rem',
                marginRight: theme.spacing(),
            },
        },
        button: {
            width: 250,
        },
    }),
)
