import React, { ComponentType, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useNavigate } from 'react-router-dom'
import { RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'

const withRedirect = (ComposedComponent: ComponentType) => {
    const WithRedirect: React.FC = ({ ...props }) => {
        const navigate = useNavigate()
        const { started, restaurantOwner } = useSelector(
            ({ onvaauresto: { auth, session } }: ApplicationState) => ({
                started: session.started,
                restaurantOwner: auth.restaurantOwner,
            }),
        )

        useEffect(() => {
            const redirect = (restaurantOwner: RestaurantOwnerModel) => {
                if (restaurantOwner.hasEmailValidated) {
                    if (restaurantOwner.establishment) {
                        navigate('/establishment/menu')
                    } else {
                        navigate('/establishment')
                    }
                } else {
                    navigate('/account/confirm')
                }
            }
            if (started && restaurantOwner) {
                redirect(restaurantOwner)
            }
        }, [started, navigate, restaurantOwner])

        return <ComposedComponent {...props} />
    }
    return WithRedirect
}

export default withRedirect
