import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import {
    Button,
    Card,
    CardMedia,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    Typography,
} from '@material-ui/core'
import ImageField from './components/imageField'
import { Image } from 'core/services/images/useImages'
import { GroupFacebookModel, LocationModel } from '@sugg-gestion/react-onvaauresto'
import { Trans, useTranslation } from 'react-i18next'
import { useFormsStyles } from './forms.css'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useStyles } from './establishmentForm.css'
import clsx from 'clsx'
import { validateAWSURL } from 'appsync-scalar-utils'
import _ from 'lodash'
import SubmitButton from 'components/common/submitButton'
import FacebookIcon from '@material-ui/icons/Facebook'
import appOvar from 'assets/main/app.png'
import WebsiteIcon from '../icons/websiteIcon'
import { Skeleton } from '@material-ui/lab'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { isValidUrl } from '../../core/services/url/isValidUrl'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EstablishmentPhotoFormState) => void
    initialValues: EstablishmentPhotoFormState
    inProgress: boolean
    onBack?: () => void
    onIgnore?: () => void
    isPhotoShared: boolean
    showPhotoShare: boolean
    forceFacebook: boolean
}

export interface EstablishmentPhotoFormState {
    url?: string
    image?: Image
    isFacebookShared: boolean
}

export interface EstablishmentPhotoFormError {
    url?: string
    image?: string
}

const EstablishmentPhotoForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    initialValues,
    inProgress,
    onBack,
    isPhotoShared,
    showPhotoShare,
    forceFacebook,
    onIgnore,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const formsClasses = useFormsStyles()

    const validate = (values: EstablishmentPhotoFormState) => {
        const errors: EstablishmentPhotoFormError = {}
        if (!values.url && !values.image?.file) {
            errors.url = t('form-validation.imageOrUrl')
            errors.image = t('form-validation.imageOrUrl')
        }
        if (values.url && !(isValidUrl(values.url) && validateAWSURL(values.url))) {
            errors.url = t('form-validation.url')
        }

        return errors
    }

    const submit = (values: EstablishmentPhotoFormState) => {
        return onSubmit({
            ...values,
            url: _.isEmpty(values.url) ? undefined : values.url,
        })
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            validate={validate}
            mutators={{
                setPosition: (args, state, tools) => {
                    const position = args[0] as LocationModel
                    tools.changeValue(state, 'location', () => position)
                },
                updateImage: (args, state, tools) => {
                    const shouldShareOnFacebook = args[0] as boolean
                    tools.changeValue(state, 'isFacebookShared', () => shouldShareOnFacebook)
                },
            }}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators } }) => {
                return (
                    <form noValidate onSubmit={handleSubmit}>
                        <Typography variant="h1" color="textSecondary">
                            <Trans i18nKey="form.photo" />
                        </Typography>
                        <Typography>
                            <Trans i18nKey="form.photoIntro" />
                        </Typography>
                        <div>
                            <ImageField
                                name="image"
                                onValueChange={() => mutators.updateImage(showPhotoShare || forceFacebook)}
                            >
                                {(Uploader, hasError, error, image) => {
                                    return (
                                        <Uploader
                                            classes={{
                                                root: clsx(formsClasses.photoField, classes.photoField),
                                                container: formsClasses.photoFieldContainer,
                                            }}
                                        >
                                            <Card
                                                elevation={0}
                                                classes={{
                                                    root: image
                                                        ? formsClasses.photoFieldCard
                                                        : formsClasses.photoFieldCardWithoutImage,
                                                }}
                                            >
                                                <CardMedia
                                                    image={image?.url}
                                                    classes={{
                                                        root: formsClasses.photoFieldCardMedia,
                                                    }}
                                                >
                                                    {!image && (
                                                        <div className={formsClasses.photoFieldAddContainer}>
                                                            <AddCircleIcon
                                                                className={clsx(
                                                                    formsClasses.photoFieldAdd,
                                                                    classes.photoFieldAdd,
                                                                )}
                                                                color="primary"
                                                            />
                                                        </div>
                                                    )}
                                                </CardMedia>
                                            </Card>
                                            {image?.url && (
                                                <div className={classes.photoEdit}>
                                                    <Button variant="contained" color="primary">
                                                        <Trans i18nKey="actions.edit" />
                                                    </Button>
                                                </div>
                                            )}
                                        </Uploader>
                                    )
                                }}
                            </ImageField>
                            {showPhotoShare && (
                                <FormSpy
                                    subscription={{
                                        values: true,
                                    }}
                                >
                                    {(props) => (
                                        <List>
                                            <Divider
                                                className={clsx({
                                                    [formsClasses.disabled]:
                                                        isPhotoShared &&
                                                        !(props.values.image?.file || props.values.url),
                                                })}
                                            />
                                            <Field name="isFacebookShared">
                                                {({ input: { value, onChange } }) => (
                                                    <ListItem
                                                        divider
                                                        button
                                                        onClick={() => onChange(!value)}
                                                        disabled={
                                                            isPhotoShared &&
                                                            !(props.values.image?.file || props.values.url)
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <Switch
                                                                color="primary"
                                                                edge="start"
                                                                checked={value}
                                                                disableRipple
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemIcon
                                                            classes={{
                                                                root: formsClasses.shareIcon,
                                                            }}
                                                        >
                                                            <FacebookIcon
                                                                className={formsClasses.facebookIcon}
                                                            />
                                                            <img
                                                                src={appOvar}
                                                                alt="On va au resto"
                                                                className={formsClasses.ovarIcon}
                                                            />
                                                            <WebsiteIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('actions.shareFacebook')} />
                                                    </ListItem>
                                                )}
                                            </Field>
                                        </List>
                                    )}
                                </FormSpy>
                            )}
                        </div>

                        <div className={clsx(formsClasses.actions, classes.actionsContainer)}>
                            {onBack!! && (
                                <Button variant="contained" onClick={onBack}>
                                    <Trans i18nKey="actions.back" />
                                </Button>
                            )}
                            {onIgnore!! && (
                                <Button variant="outlined" onClick={onIgnore}>
                                    <Trans i18nKey="actions.ignore" />
                                </Button>
                            )}
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={submitting || inProgress}
                                className={formsClasses.submit}
                            >
                                <Trans i18nKey="actions.validateAndShare" />
                            </SubmitButton>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}

export default EstablishmentPhotoForm
