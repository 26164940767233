import React from 'react'
import { useFormsStyles } from './forms.css'
import { Form } from 'react-final-form'
import TextField from './components/textField'
import SubmitButton from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { validateAWSEmail } from 'appsync-scalar-utils'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: CheckFormState) => void
    inProgress: boolean
    initialValues: CheckFormState
}

export interface CheckFormState {
    email: string
}

interface CheckFormError {
    email?: string
}

const CheckForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    inProgress,
    initialValues,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const validate = (values: CheckFormState) => {
        const errors: CheckFormError = {}
        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        <TextField
                            name="email"
                            label={t('form.email')}
                            autoComplete="email"
                            type="email"
                            required
                        />
                    </div>
                    <div className={formsClasses.actionsInline}>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default CheckForm
