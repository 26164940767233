import { Reducer } from 'redux'
import { AlertContent, AppState, SnackbarNotification } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { SnackbarKey } from 'notistack'
import { defineAbility } from '@casl/ability'
import { actions as onVaAuRestoActions, RestaurantOwnerSignInResponse } from '@sugg-gestion/react-onvaauresto'

const defaultAbility = defineAbility(() => {})

const initialState: AppState = {
    locale: 'fr-FR',
    timezone: 'Europe/Paris',
    init: false,
    notifications: [],
    ability: defaultAbility,
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setLocale): {
            const locale = action.payload
            return {
                ...state,
                locale,
            }
        }
        case getType(actions.setTimezone): {
            const timezone = action.payload
            return {
                ...state,
                timezone,
            }
        }
        case getType(actions.init): {
            const init: boolean = action.payload
            return {
                ...state,
                init,
            }
        }
        case getType(actions.enqueueSnackbar): {
            const notification: SnackbarNotification = action.payload
            const key = notification.options && notification.options.key
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...notification,
                        key: key || new Date().getTime() + Math.random(),
                    },
                ],
            }
        }
        case getType(actions.closeSnackbar): {
            const key: SnackbarKey = action.payload
            const dismissAll = key !== undefined
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    dismissAll || notification.key === key
                        ? { ...notification, dismissed: true }
                        : { ...notification },
                ),
            }
        }
        case getType(actions.removeSnackbar): {
            const key: SnackbarKey = action.payload
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== key),
            }
        }
        case getType(actions.alert): {
            const alert: AlertContent = action.payload
            return {
                ...state,
                alert,
            }
        }
        case getType(onVaAuRestoActions.restaurantOwnerSignIn): {
            const response: RestaurantOwnerSignInResponse = action.payload
            const { restaurantOwner } = response
            const ability = defineAbility((can) => {
                if (!restaurantOwner.hasEmailValidated) {
                    can('confirm', 'account')
                } else {
                    if (restaurantOwner.establishment) {
                        can('update', 'establishment')
                    } else {
                        can('create', 'establishment')
                    }
                }
            })
            return {
                ...state,
                ability,
            }
        }
        case getType(onVaAuRestoActions.restaurantOwnerSignOut): {
            return {
                ...state,
                ability: defaultAbility,
            }
        }
        default:
            return state
    }
}

export { reducer as appReducer }
