const regex = /^https?:\/\//
export const isValidUrl = (text: string) => {
    if (!regex.test(text)) {
        return false
    }
    let url

    try {
        url = new URL(text)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}
