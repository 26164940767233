import { Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './resend.css'
import SubmitButton from '../../common/submitButton'

interface Props {
    loading: boolean
    disabled: boolean
    onResend: () => void
}

const Resend: React.FC<Props> = ({ loading, disabled, onResend }) => {
    const classes = useStyles()
    return (
        <div className={classes.resend}>
            <Typography>
                <Trans i18nKey="email-validation.resend" />
            </Typography>
            <SubmitButton
                variant="outlined"
                color="primary"
                disabled={disabled}
                loading={loading}
                onClick={onResend}
                className={classes.button}
            >
                <Trans i18nKey="actions.emailValidationResend" />
            </SubmitButton>
        </div>
    )
}

export default Resend
