import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2, 0),
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'column',
            flexGrow: 1,
        },
        icon: {
            fontSize: '8rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '5rem',
            },
        },
        title: {
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '4rem',
            lineHeight: '4rem',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
                lineHeight: '2.4rem',
            },
        },
        subTitle: {
            fontWeight: theme.fontsWeight.light,
            fontSize: '2rem',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.4rem',
            },
        },
        alert: {
            marginTop: theme.spacing(2),
        },
    }),
)
