import React from 'react'
import { Field } from 'react-final-form'
import PhotoUpload from 'components/common/photoUpload'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Image, useImages } from 'core/services/images/useImages'
import { FormControl, FormHelperText, PropTypes } from '@material-ui/core'

interface UploaderProps {
    classes?: {
        root?: string
        container?: string
    }
}

interface Props {
    name: string
    defaultValue?: Image
    children: (
        Uploader: React.FC<UploaderProps>,
        hasError: boolean,
        error?: string,
        image?: Image,
    ) => React.ReactNode
    margin?: PropTypes.Margin
    required?: boolean
    onValueChange?: () => void
}

const ImageField: React.FC<Props> = ({
    name,
    defaultValue,
    children,
    margin = 'normal',
    required,
    onValueChange,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { updateImages } = useImages()

    return (
        <Field name={name} defaultValue={defaultValue}>
            {({ input: { onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <FormControl
                        component="fieldset"
                        fullWidth
                        margin={margin}
                        required={required}
                        error={hasError}
                    >
                        {children(
                            ({ classes, children }) => (
                                <PhotoUpload
                                    classes={classes}
                                    multiple={true}
                                    files={[]}
                                    accept={['image/jpeg', 'image/png']}
                                    onChange={(files) => {
                                        if (files !== undefined) {
                                            updateImages(files, []).then((images) => {
                                                onChange(images[0])
                                                if (onValueChange) {
                                                    onValueChange()
                                                }
                                            })
                                        }
                                    }}
                                    // 5000000 = 5 MB
                                    maxSize={parseInt(
                                        process.env.REACT_APP_PHOTO_MAX_SIZE !== undefined
                                            ? process.env.REACT_APP_PHOTO_MAX_SIZE
                                            : '5000000',
                                    )}
                                    isInvalidValid={(files) => {
                                        for (let i = 0; i < files.length; i++) {
                                            enqueueSnackbar(
                                                t('form-validation.photo').replace(
                                                    ':name',
                                                    files[i].file.name,
                                                ),
                                                {
                                                    variant: 'error',
                                                },
                                            )
                                        }
                                        return true
                                    }}
                                >
                                    {children}
                                </PhotoUpload>
                            ),
                            hasError || false,
                            meta.error,
                            value,
                        )}
                        {hasError && <FormHelperText error={hasError}>{meta.error}</FormHelperText>}
                    </FormControl>
                )
            }}
        </Field>
    )
}

export default ImageField
