import { RestaurantOwnerForgottenPasswordConfirmRequest } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'

export const useRestaurantOwnerForgottenPasswordConfirm = () => {
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnerForgottenPasswordConfirm = (
        payload: RestaurantOwnerForgottenPasswordConfirmRequest,
    ): Promise<void> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerForgottenPasswordConfirm(payload)
            .then(() => {
                setInProgress(false)
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        restaurantOwnerForgottenPasswordConfirm,
        inProgress,
    }
}
