import actions from '../../../core/store/actions'
import { RestaurantOwnerConfirmRequest, RestaurantOwnerSignInResponse } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'
import { useDispatch } from '../redux'

export const useRestaurantOwnerConfirm = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnerConfirm = (
        token: string,
        payload: RestaurantOwnerConfirmRequest,
    ): Promise<RestaurantOwnerSignInResponse> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerConfirm(token, payload)
            .then(({ data }) => {
                setInProgress(false)
                if (data) {
                    dispatch(actions.restaurantOwnerSignIn(data))
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                setInProgress(false)
                throw generateError(error)
            })
    }

    return {
        restaurantOwnerConfirm,
        inProgress,
    }
}
