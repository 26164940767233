import React from 'react'
import Home from 'containers/auth/home'
import Confirm from 'containers/account/confirm'
import Establishment from 'containers/establishment/establishment'
import Address from 'containers/establishment/address'
import Account from 'containers/account/account'
import Details from 'containers/establishment/details'
import Menu from 'containers/establishment/menu'
import MenuReplace from '../../containers/establishment/menuReplace'

export interface InternalRoute {
    path: string
    component?: React.FC<any>
    children?: Array<InternalRoute>
}

export const routes: Array<InternalRoute> = [
    {
        path: '',
        component: Home,
    },
    {
        path: 'account',
        component: Account,
        children: [
            {
                path: 'confirm',
                component: Confirm,
            },
        ],
    },
    {
        path: 'establishment',
        component: Establishment,
        children: [
            {
                path: 'details',
                component: Details,
            },
            {
                path: 'address',
                component: Address,
            },
            {
                path: 'menu',
                component: Menu,
                children: [
                    {
                        path: 'replace',
                        component: MenuReplace,
                    },
                ],
            },
        ],
    },
]
