import { Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './card.css'

const CheckCard: React.FC = ({ children }) => {
    const classes = useStyles()
    return (
        <>
            <Typography color="textPrimary" className={classes.title}>
                <Trans
                    i18nKey="home.check.title"
                    components={[<Typography component="span" color="primary" className={classes.inherit} />]}
                />
            </Typography>
            <Typography color="textPrimary">
                <Trans i18nKey="home.check.message" />
            </Typography>
            {children}
        </>
    )
}

export default CheckCard
