import { createAction } from 'typesafe-actions'
import { SnackbarKey } from 'notistack'
import { AlertContent, AppActionTypes, SnackbarErrorNotification, SnackbarNotificationAction } from './types'

export const setLocale = createAction(AppActionTypes.SET_LOCALE)<string>()
export const setTimezone = createAction(AppActionTypes.SET_TIMEZONE)<string>()
export const init = createAction(AppActionTypes.INIT)<boolean>()

export const enqueueSnackbar = createAction(AppActionTypes.ENQUEUE_SNACKBAR)<SnackbarNotificationAction>()
export const enqueueError = createAction(AppActionTypes.ENQUEUE_ERROR)<SnackbarErrorNotification>()
export const closeSnackbar = createAction(AppActionTypes.CLOSE_SNACKBAR)<SnackbarKey>()
export const removeSnackbar = createAction(AppActionTypes.REMOVE_SNACKBAR)<SnackbarKey>()

export const alert = createAction(AppActionTypes.ALERT)<AlertContent | undefined>()
