import React from 'react'
import { Card, CardMedia, Typography } from '@material-ui/core'
import { useStyles } from './confirmationRow.css'
import { Image } from 'core/services/images/useImages'
import { Trans } from 'react-i18next'

interface Props {
    url?: string
    image?: Image
}

const ConfirmationPhotoRow: React.FC<Props> = ({ url, image }) => {
    const classes = useStyles()
    return (
        <div className={classes.row}>
            {image?.url ? (
                <Card
                    elevation={0}
                    classes={{
                        root: classes.photoFieldCard,
                    }}
                >
                    <CardMedia
                        image={image?.url ?? url}
                        classes={{
                            root: classes.photoFieldCardMedia,
                        }}
                    />
                </Card>
            ) : (
                <Typography>
                    <Trans i18nKey="form.photoIgnore" />
                </Typography>
            )}
        </div>
    )
}

export default ConfirmationPhotoRow
