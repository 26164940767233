import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            display: 'flex',
            margin: theme.spacing(2, 0),
            paddingTop: theme.spacing(3),
        },
        title: {
            width: 300,
            maxWidth: '100%',
            paddingRight: theme.spacing(),
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                flexGrow: 1,
            },
        },
    }),
)
