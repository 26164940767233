import { Container } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import SignInCard from 'components/views/auth/signInCard'
import SignUpCard from 'components/views/auth/signUpCard'
import SignInForm, { SignInFormState } from 'components/forms/signInForm'
import SignUpForm, { SignUpFormState } from 'components/forms/signUpForm'
import {
    EstablishmentModel,
    RestaurantOwnerCheckRequest,
    useRestaurantOwnerCheck,
    useRestaurantOwnerForgottenPassword,
    useRestaurantOwnerForgottenPasswordConfirm,
    useRestaurantOwnerResendEstablishmentCode,
    useRestaurantOwnerSignInByEmail,
    useRestaurantOwnerSignUp,
} from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import Cgu from 'components/views/auth/cgu'
import MainLayout from 'components/layouts/mainLayout'
import ForgottenPasswordConfirmDialog from 'components/dialogs/forgottenPasswordConfirmDialog'
import ForgottenPasswordConfirmForm, {
    ForgottenPasswordConfirmFormState,
} from 'components/forms/forgottenPasswordConfirmForm'
import CheckForm, { CheckFormState } from 'components/forms/checkForm'
import CheckCard from 'components/views/auth/checkCard'
import EstablishmentSelectCard from 'components/views/auth/establishmentSelectCard'
import LoadingCard from 'components/views/auth/loadingCard'
import HomeView from 'components/views/auth/homeView'
import withRedirect from 'containers/hoc/withRedirect'

enum HOME_SCREENS {
    FETCH,
    CHECK,
    SIGN_IN,
    SIGN_UP,
    ESTABLISHMENT_SELECT,
}

const Home: React.FC = () => {
    const { displayError } = useApiErrors()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const [searchParams] = useSearchParams()

    const [screen, setScreen] = useState<HOME_SCREENS>(() => {
        if (searchParams.get('email')) {
            return HOME_SCREENS.FETCH
        }
        return HOME_SCREENS.CHECK
    })
    const [email, setEmail] = useState<string | undefined>(() => {
        return searchParams.get('email') ?? undefined
    })
    const [fromHome, setFromHome] = useState<boolean>(false)
    const [initialFetchInProgress, setInitialFetchInProgress] = useState(false)
    const [establishments, setEstablishments] = useState<Array<EstablishmentModel>>([])
    const [establishment, setEstablishment] = useState<EstablishmentModel>()
    const [resendCodeSent, setResendCodeSent] = useState(false)

    const { signInRestaurantOwnerByEmail, inProgress: signInRestaurantOwnerByEmailInProgress } =
        useRestaurantOwnerSignInByEmail()
    const { signUpRestaurantOwner, inProgress: signUpRestaurantOwnerInProgress } = useRestaurantOwnerSignUp()
    const { restaurantOwnerForgottenPassword, inProgress: restaurantOwnerForgottenPasswordInProgress } =
        useRestaurantOwnerForgottenPassword()
    const {
        restaurantOwnerForgottenPasswordConfirm,
        inProgress: restaurantOwnerForgottenPasswordConfirmInProgress,
    } = useRestaurantOwnerForgottenPasswordConfirm()
    const { restaurantOwnerCheck, inProgress: checkInProgress } = useRestaurantOwnerCheck()
    const { restaurantOwnerResendEstablishmentCode, inProgress: resendEstablishmentCodeInProgress } =
        useRestaurantOwnerResendEstablishmentCode()

    const [openForgottenPasswordConfirmDialog, setOpenForgottenPasswordConfirmDialog] = useState<
        string | undefined
    >(undefined)

    const handleSignIn = (values: SignInFormState) => {
        signInRestaurantOwnerByEmail({
            email: values.email,
            password: values.password,
            withLongLifeToken:
                values.remindMe && values.remindMe.length > 0 ? values.remindMe[0] : undefined ?? false,
        })
            .then(() => {
                enqueueSnackbar(t('home.sign-in.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const handleSignUp = (values: SignUpFormState) => {
        signUpRestaurantOwner({
            givenName: values.givenName,
            familyName: values.familyName,
            email: values.email,
            password: values.password,
            code: values.code,
            phone: values.phone,
            country: values.country,
            withLongLifeToken:
                values.remindMe && values.remindMe.length > 0 ? values.remindMe[0] : undefined ?? false,
        })
            .then(() => {
                enqueueSnackbar(t('home.sign-up.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const handleOpenForgottenPassword = () => {
        if (!email) {
            return
        }
        restaurantOwnerForgottenPassword({
            email,
        })
            .then(() => {
                setOpenForgottenPasswordConfirmDialog(email)
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const handleForgottenPasswordConfirmSubmit = (values: ForgottenPasswordConfirmFormState) => {
        restaurantOwnerForgottenPasswordConfirm({
            ...values,
            email: openForgottenPasswordConfirmDialog ?? '',
        })
            .then(() => {
                setOpenForgottenPasswordConfirmDialog(undefined)
                enqueueSnackbar(t('home.forgotten-password-confirm.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const handleCheck = (values: CheckFormState) => {
        restaurantOwnerCheck({
            email: values.email,
        })
            .then((response) => {
                setEmail(values.email)
                switch (response.type) {
                    case 'ACCOUNT':
                        setScreen(HOME_SCREENS.SIGN_IN)
                        break
                    case 'UNKNOWN':
                        setScreen(HOME_SCREENS.SIGN_UP)
                        setFromHome(true)
                        break
                    case 'ESTABLISHMENTS':
                        setEstablishments(response.establishments)
                        setScreen(HOME_SCREENS.ESTABLISHMENT_SELECT)
                        break
                }
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const handleBackCheck = () => {
        setEstablishments([])
        setEstablishment(undefined)
        setResendCodeSent(false)
        setFromHome(false)
        setScreen(HOME_SCREENS.CHECK)
    }

    const handleGoSignUp = () => {
        setScreen(HOME_SCREENS.SIGN_UP)
    }

    const handleChooseEstablishment = (establishment: EstablishmentModel) => {
        setEstablishment(establishment)
        setScreen(HOME_SCREENS.SIGN_UP)
    }

    const handleResendEstablishmentCode = (establishment: EstablishmentModel) => {
        restaurantOwnerResendEstablishmentCode({
            establishment: establishment.id,
        })
            .then(() => {
                setResendCodeSent(true)
                enqueueSnackbar(t('home.resend-establishment-code.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const restaurantOwnerCheckCallback = useCallback(
        (payload: RestaurantOwnerCheckRequest) => {
            if (!initialFetchInProgress) {
                setInitialFetchInProgress(true)
                restaurantOwnerCheck(payload)
                    .then((response) => {
                        switch (response.type) {
                            case 'ACCOUNT':
                                setScreen(HOME_SCREENS.SIGN_IN)
                                break
                            case 'UNKNOWN':
                                setScreen(HOME_SCREENS.SIGN_UP)
                                break
                            case 'ESTABLISHMENTS':
                                setEstablishments(response.establishments)
                                const establishmentCode = searchParams.get('establishmentCode')?.toLowerCase()
                                if (establishmentCode) {
                                    const establishment = response.establishments.find(
                                        (establishment) => establishment.id === establishmentCode,
                                    )
                                    if (establishment) {
                                        setEstablishment(establishment)
                                        setScreen(HOME_SCREENS.SIGN_UP)
                                        break
                                    }
                                }
                                setScreen(HOME_SCREENS.ESTABLISHMENT_SELECT)
                                break
                        }
                    })
                    .catch(() => {
                        setScreen(HOME_SCREENS.CHECK)
                    })
                    .finally(() => {
                        setInitialFetchInProgress(false)
                    })
            }
        },
        [initialFetchInProgress, restaurantOwnerCheck, searchParams],
    )

    useEffect(() => {
        if (screen === HOME_SCREENS.FETCH && email) {
            restaurantOwnerCheckCallback({
                email,
            })
        }
    }, [email, restaurantOwnerCheckCallback, screen])

    const getScreen = () => {
        switch (screen) {
            case HOME_SCREENS.CHECK:
                return (
                    <CheckCard>
                        <CheckForm
                            onSubmit={handleCheck}
                            inProgress={checkInProgress}
                            initialValues={{
                                email: email ?? '',
                            }}
                        />
                    </CheckCard>
                )
            case HOME_SCREENS.SIGN_IN:
                return (
                    <SignInCard>
                        <SignInForm
                            initialValues={{
                                email: email ?? '',
                                remindMe: [true],
                            }}
                            onSubmit={handleSignIn}
                            inProgress={signInRestaurantOwnerByEmailInProgress}
                            openForgottenPassword={handleOpenForgottenPassword}
                            openForgottenPasswordInProgress={restaurantOwnerForgottenPasswordInProgress}
                            onBack={handleBackCheck}
                        />
                        <ForgottenPasswordConfirmDialog
                            open={openForgottenPasswordConfirmDialog !== undefined}
                            handleClose={() => setOpenForgottenPasswordConfirmDialog(undefined)}
                            email={openForgottenPasswordConfirmDialog ?? ''}
                        >
                            <ForgottenPasswordConfirmForm
                                initialValues={{
                                    code: '',
                                    password: '',
                                    passwordConfirmation: '',
                                }}
                                onSubmit={handleForgottenPasswordConfirmSubmit}
                                inProgress={restaurantOwnerForgottenPasswordConfirmInProgress}
                            />
                        </ForgottenPasswordConfirmDialog>
                    </SignInCard>
                )
            case HOME_SCREENS.SIGN_UP:
                return (
                    <>
                        <SignUpCard fromHome={fromHome} onBack={handleBackCheck}>
                            <SignUpForm
                                initialValues={{
                                    email: email ?? '',
                                    givenName: '',
                                    familyName: '',
                                    country: 'FR',
                                    phone: '',
                                    code: searchParams.get('establishmentCode')?.toLowerCase() ?? undefined,
                                    remindMe: [true],
                                }}
                                onSubmit={handleSignUp}
                                inProgress={signUpRestaurantOwnerInProgress}
                                onBack={handleBackCheck}
                                establishment={establishment}
                                resendCode={handleResendEstablishmentCode}
                                resendCodeInProgress={resendEstablishmentCodeInProgress}
                                resendCodeDisabled={resendCodeSent}
                            />
                        </SignUpCard>
                        <Cgu />
                    </>
                )
            case HOME_SCREENS.ESTABLISHMENT_SELECT:
                return (
                    <EstablishmentSelectCard
                        establishments={establishments}
                        email={email ?? ''}
                        onChooseEstablishment={handleChooseEstablishment}
                        onCancel={handleGoSignUp}
                    />
                )
            case HOME_SCREENS.FETCH:
                return <LoadingCard />
        }
        return null
    }

    return (
        <MainLayout>
            <Container maxWidth="sm">
                <HomeView>{getScreen()}</HomeView>
            </Container>
        </MainLayout>
    )
}

export default withRedirect(Home)
