import React from 'react'
import { compose } from 'redux'
import { Route, Routes } from 'react-router-dom'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import withHistory from 'containers/hoc/withHistory'
import withNotistack from 'containers/hoc/withNotistack'
import withStoreProvider from 'containers/hoc/withStoreProvider'
import { InternalRoute, routes } from 'core/config/routes'
import withTranslation from 'containers/hoc/withTranslation'
import withAlert from 'containers/hoc/withAlert'
import withOnVaAuResto from 'containers/hoc/withOnVaAuResto'
import withSaga from 'containers/hoc/withSaga'
import withInit from 'containers/hoc/withInit'
import StandardLayout from 'components/layouts/standardLayout'
import withHubSpot from '../hoc/withHubSpot'
import NotFound from './notFound'

const App: React.FC = () => {
    const path = (routes: Array<InternalRoute>) => {
        let path = '/'
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i]
            path = path + route.path + '/'
        }
        path = path.substring(0, path.length - 1)
        return path
    }

    const displayRoutes = (route: InternalRoute, parents: Array<InternalRoute>): Array<React.ReactNode> => {
        const routes = []
        if (route.component !== undefined) {
            const Page = route.component
            routes.push(
                <Route path={path([...parents, route])} key={path([...parents, route])} element={<Page />} />,
            )
        }
        if (route.children !== undefined && route.children.length > 0) {
            for (let i = 0; i < route.children.length; i++) {
                const childRoute = route.children[i]
                routes.push(...displayRoutes(childRoute, [...parents, route]))
            }
        }
        return routes
    }

    return (
        <StandardLayout>
            <Routes>
                {routes.map((mainRoute) => {
                    return displayRoutes(mainRoute, [])
                })}
                <Route element={<NotFound />} path="*" />
            </Routes>
        </StandardLayout>
    )
}

export default compose<React.FC>(
    withHistory,
    withTranslation,
    withStoreProvider,
    withOnVaAuResto,
    withSaga,
    withMuiTheme,
    withInit,
    withNotistack,
    withAlert,
    withHubSpot,
)(App)
