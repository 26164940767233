import React from 'react'
import { useFormsStyles } from './forms.css'
import { Form, FormSpy } from 'react-final-form'
import TextField from './components/textField'
import PasswordField from './components/passwordField'
import SubmitButton from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useStyles } from './accountForm.css'
import { validateAWSEmail } from 'appsync-scalar-utils'
import useTheme from '@material-ui/core/styles/useTheme'
import PasswordRules from './components/passwordRules'
import CountryField from './components/countryField'
import PhoneField from './components/phoneField'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: AccountFormState) => void
    initialValues: AccountFormState
    inProgress: boolean
    disableCode: boolean
}

export interface AccountFormState {
    givenName: string
    familyName: string
    email: string
    country: CountryCode
    phone: string
    password?: string
    passwordConfirmation?: string
    code?: string
}

interface AccountFormError {
    givenName?: string
    familyName?: string
    email?: string
    country?: string
    phone?: string
    password?: string
    passwordConfirmation?: string
    code?: string
}

const AccountForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    inProgress,
    initialValues,
    disableCode,
}) => {
    const classes = useStyles()
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: AccountFormState) => {
        const errors: AccountFormError = {}
        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }
        if (values.password && !strongPasswordRegex.test(values.password)) {
            errors.password = t('form-validation.passwordStrength')
        }

        if (
            (values.passwordConfirmation || values.password) &&
            values.password !== values.passwordConfirmation
        ) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmationNotMatch')
        }

        if (!values.givenName) {
            errors.givenName = t('form-validation.givenName')
        }
        if (!values.familyName) {
            errors.familyName = t('form-validation.familyName')
        }

        if (values.phone && values.phone !== '') {
            const phone = parsePhoneNumberFromString(values.phone, values.country)
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('form-validation.phone')
            }
        } else {
            errors.phone = t('form-validation.phoneRequired')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={isMobile ? 0 : 6}>
                        <Grid item sm={6} xs={12}>
                            <TextField name="givenName" label={t('form.givenName')} required />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField name="familyName" label={t('form.familyName')} required />
                        </Grid>
                    </Grid>
                    <TextField
                        name="email"
                        label={t('form.email')}
                        autoComplete="email"
                        type="email"
                        required
                    />
                    <CountryField label={t('form.country')} name="country" required />
                    <PhoneField label={t('form.phone')} name="phone" required />
                    <div className={classes.establishmentCode}>
                        <Typography>
                            <Trans i18nKey="form.updatePassword" />
                        </Typography>
                        <PasswordField
                            margin="none"
                            name="password"
                            label={t('form.passwordNew')}
                            autoComplete="new-password"
                        />
                        <FormSpy subscription={{ values: true }}>
                            {(props) => <PasswordRules value={props.values.password} />}
                        </FormSpy>
                        <PasswordField
                            name="passwordConfirmation"
                            label={t('form.passwordConfirmation')}
                            autoComplete="new-password"
                        />
                    </div>
                    {!disableCode && (
                        <div className={classes.establishmentCode}>
                            <TextField name="code" label={t('form.establishmentCode')} margin="none" />
                        </div>
                    )}
                    <div className={formsClasses.actions}>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default AccountForm
