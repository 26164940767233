import React from 'react'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { Trans, useTranslation } from 'react-i18next'
import { Button, FormControl, Grid, Typography } from '@material-ui/core'
import { useStyles } from './establishmentPhotoView.css'
import { useImgix } from 'core/services/imgix/useImgix'
import { Link } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

interface Props {
    establishment: EstablishmentModel
}

const EstablishmentPhotoView: React.FC<Props> = ({ establishment }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { getImage } = useImgix()
    return (
        <Grid container spacing={4}>
            <Grid item lg={4} md={6} xs={12}>
                <Typography variant="h1" color="textSecondary">
                    <Trans i18nKey="form.photo" />
                </Typography>
                <FormControl margin="normal" fullWidth>
                    <div className={classes.imageContainer}>
                        {establishment.photo?.url ? (
                            <img
                                src={getImage(establishment.photo?.url, { h: 400, w: 400 })}
                                alt={t('establishment.photo.title')}
                            />
                        ) : (
                            <Typography>
                                <Trans i18nKey="form.photoIgnore" />
                            </Typography>
                        )}
                    </div>
                </FormControl>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
                {/*  ACTIONS  */}
                <Typography color="textSecondary" variant="h1" className={classes.actions}>
                    <Trans i18nKey="form.actions" />
                </Typography>
                <FormControl margin="normal">
                    <Button
                        component={Link}
                        to="/establishment/menu/replace"
                        variant="contained"
                        color="primary"
                        endIcon={<NavigateNextIcon />}
                    >
                        <Trans i18nKey="actions.updateEstablishment" />
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default EstablishmentPhotoView
