import { Divider, Grid, Link, ListSubheader, Typography } from '@material-ui/core'
import website from 'assets/main/website.png'
import wheel from 'assets/main/wheel3.gif'
import StudentIcon from 'components/icons/studentIcon'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './shareLocation.css'

interface Props {
    title?: string
}

interface PropsAdvantageListItem {
    title: React.ReactNode
    icon: React.ReactNode
}

const AdvantageListItem: React.FC<PropsAdvantageListItem> = ({ title, icon }) => {
    const classes = useStyles()
    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="flex-end" className={classes.item}>
                <Grid item className={classes.titleContainer}>
                    <Grid container alignItems="center" className={classes.itemTitle}>
                        <Grid item className={classes.shareIcon}>
                            {icon}
                        </Grid>
                        <Grid item>
                            <Typography>{title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
        </Grid>
    )
}

const Advantage: React.FC<Props> = ({ title = 'establishment.advantage.on' }) => {
    const classes = useStyles()

    return (
        <Grid container>
            <Grid item xs={12}>
                <ListSubheader className={classes.title}>
                    <Trans i18nKey={title} />
                </ListSubheader>
            </Grid>
            <AdvantageListItem
                title={
                    <Trans
                        i18nKey="establishment.advantage.formation"
                        components={[<Link href="https://resto.suggpro.com/formation" />]}
                    />
                }
                icon={<StudentIcon fontSize="large" />}
            />
            <AdvantageListItem
                title={
                    <Trans
                        i18nKey="establishment.advantage.gaming"
                        components={[<Link href="https://resto.suggpro.com/gamification-accessible" />]}
                    />
                }
                icon={
                    <span className={classes.icon}>
                        <img src={wheel} alt="Gaming" className={classes.imageIconLarge} />
                    </span>
                }
            />
            <AdvantageListItem
                title={
                    <Trans
                        i18nKey="establishment.advantage.website"
                        components={[<Link href="https://resto.suggpro.com/site-web-by-onvaauresto" />]}
                    />
                }
                icon={
                    <span className={classes.icon}>
                        <img src={website} alt="Site web" className={classes.imageIconLarge} />
                    </span>
                }
            />
        </Grid>
    )
}

export default Advantage
