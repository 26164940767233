import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import { useStyles } from './websiteIcon.css'

interface Props {
    className?: string
}

const TwitterRoundIcon: React.FC<SvgIconProps & Props> = (props) => {
    const classes = useStyles()
    return (
        <SvgIcon {...props} viewBox="0 0 496 496" classes={classes}>
            <g>
                <path
                    d="M365.008,0H130.992C57.536,0,0,57.536,0,130.992v234.016C0,438.464,57.536,496,130.992,496h234.016
				C438.464,496,496,438.464,496,365.008V130.992C496,57.536,438.448,0,365.008,0z M464,365.008
				c0,55.52-43.488,98.992-98.992,98.992H130.992C75.488,464,32,420.512,32,365.008V130.992C32,75.488,75.488,32,130.992,32h234.016
				C420.528,32,464,75.488,464,130.992V365.008z"
                />
                <path
                    d="M403.984,126.64c-5.136-3.76-12.016-4.08-17.504-0.88c-5.472,3.216-17.6,4.8-27.328,6.08c-2.016,0.272-4,0.528-5.92,0.8
				c-13.616-11.296-31.008-17.632-48.944-17.632c-40.4,0-73.552,31.264-75.904,70.592c-36.64-6.176-70.192-25.28-93.968-53.984
				c-3.536-4.288-9.024-6.384-14.56-5.664c-5.504,0.768-10.208,4.336-12.432,9.424c-1.024,2.336-9.952,24.304-9.952,78.64
				c0,47.056,37.136,81.632,62.608,99.824c-17.424,7.92-37.504,10.832-56.576,8.72c-7.456-0.8-14.432,3.584-16.896,10.608
				c-2.464,7.04,0.256,14.848,6.56,18.832c30.08,18.976,64.848,29.008,100.56,29.008c114.688,0,184.096-90.096,186.672-178.928
				c3.296-4.784,9.584-14.096,20.208-30.432c4.256-6.576,7.824-20.752,9.568-28.608C411.568,136.832,409.12,130.384,403.984,126.64z
				 M351.536,187.536c-1.968,2.72-3.04,6.016-3.04,9.376c0,63.152-48.016,152.096-154.752,152.096
				c-9.648,0-19.216-0.88-28.592-2.608c12.512-4.736,24.336-11.344,35.2-19.712c4.352-3.344,6.672-8.72,6.16-14.176
				c-0.512-5.456-3.792-10.288-8.704-12.768c-0.688-0.352-68.32-35.92-68.32-85.712c0-16.688,0.896-29.808,2.08-39.792
				c31.408,27.04,71.376,43.216,113.44,45.296c0.32,0.016,0.656,0.032,0.96,0.016c9.312-0.272,16.16-7.104,16.16-16
				c0-2.208-0.448-4.304-1.264-6.224c-0.4-2.4-0.608-4.816-0.608-7.232c0-23.76,19.744-43.088,44.032-43.088
				c12.192,0,23.92,4.992,32.192,13.68c3.776,3.936,9.296,5.712,14.624,4.672c3.632-0.688,7.824-1.232,12.224-1.808
				c1.568-0.208,3.152-0.416,4.768-0.64C356.08,181.216,351.744,187.248,351.536,187.536z"
                />
            </g>
        </SvgIcon>
    )
}

export default TwitterRoundIcon
