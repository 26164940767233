import React from 'react'
import { useFormsStyles } from './forms.css'
import { Form } from 'react-final-form'
import TextField from './components/textField'
import PasswordField from './components/passwordField'
import SubmitButton from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import CheckboxField from './components/checkboxField'
import { Button } from '@material-ui/core'
import { useStyles } from './signInForm.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SignInFormState) => void
    inProgress: boolean
    openForgottenPassword: () => void
    openForgottenPasswordInProgress: boolean
    initialValues: Omit<SignInFormState, 'password'>
    onBack: () => void
}

export interface SignInFormState {
    email: string
    password: string
    remindMe?: Array<boolean>
}

interface SignInFormError {
    email?: string
    password?: string
}

const SignInForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    inProgress,
    openForgottenPassword,
    openForgottenPasswordInProgress,
    initialValues: defaultInitialValues,
    onBack,
}) => {
    const classes = useStyles()
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    let initialValues: SignInFormState = {
        ...defaultInitialValues,
        password: '',
    }

    const validate = (values: SignInFormState) => {
        const errors: SignInFormError = {}
        if (!values.email) {
            errors.email = t('form-validation.email')
        }
        if (!values.password) {
            errors.password = t('form-validation.password')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        <TextField
                            name="email"
                            label={t('form.email')}
                            autoComplete="email"
                            type="email"
                            required
                            disabled
                        />
                        <PasswordField name="password" label={t('form.password')} required />
                        <div className={classes.forgottenPassword}>
                            <SubmitButton
                                loading={openForgottenPasswordInProgress}
                                onClick={() => openForgottenPassword()}
                            >
                                <Trans i18nKey="actions.forgottenPassword" />
                            </SubmitButton>
                        </div>
                        <CheckboxField
                            name="remindMe"
                            label={t('form.remindMe')}
                            value={true}
                            labelPlacement="end"
                        />
                    </div>
                    <div className={formsClasses.actionsInline}>
                        <Button variant="outlined" disabled={submitting || inProgress} onClick={onBack}>
                            <Trans i18nKey="actions.back" />
                        </Button>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default SignInForm
