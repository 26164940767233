import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core'
import React from 'react'
import { AlertContent } from 'core/store/app/types'
import { Trans } from 'react-i18next'

interface Props extends AlertContent {
    onClose: () => void
}

const AlertDialog: React.FC<Props> = ({ title, onClose, content, callback, buttons }) => {
    const confirm = () => {
        callback()
        onClose()
    }
    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            {content && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {buttons && buttons.no ? buttons.no : <Trans i18nKey="common.no" />}
                </Button>
                <Button onClick={confirm} color="primary" autoFocus>
                    {buttons && buttons.yes ? buttons.yes : <Trans i18nKey="common.yes" />}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog
