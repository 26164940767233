import React from 'react'
import { Dialog, DialogContent, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './loadingDialog.css'

interface Props {
    open: boolean
    loadingMessage: string
}
const LoadingDialog: React.FC<Props> = ({ open, loadingMessage }) => {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            classes={{
                paper: classes.paper,
            }}
        >
            <DialogContent className={classes.content}>
                <CircularProgress size="4rem" />
                <Typography>{loadingMessage}</Typography>
            </DialogContent>
        </Dialog>
    )
}

export default LoadingDialog
