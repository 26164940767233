import React from 'react'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useStyles } from './confirmationRow.css'
import { useTheme } from '@material-ui/core/styles'

interface Props {
    title: string
    value?: string
}

const ConfirmationRow: React.FC<Props> = ({ title, value }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Grid container spacing={isMobile ? 0 : 1} className={classes.row}>
            <Grid item sm={4} xs={12}>
                <Typography className={classes.title} color="textSecondary">
                    <Trans i18nKey={title} />
                </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
                <Typography>{value ?? '-'}</Typography>
            </Grid>
        </Grid>
    )
}

export default ConfirmationRow
