import { Link, Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './intro.css'

interface Props {
    className?: string
}

const Intro: React.FC<Props> = ({ className }) => {
    const classes = useStyles()
    return (
        <div className={className}>
            <Typography color="textPrimary" variant="h1" className={classes.title}>
                <Trans i18nKey="home.intro.title" />
            </Typography>
            <Typography color="textPrimary" className={classes.paragraph}>
                <Trans
                    i18nKey="home.intro.message.1"
                    components={[<span style={{ textDecoration: 'underline' }} />]}
                />
            </Typography>
            <Typography component="ul" className={classes.paragraph}>
                <Typography component="li" color="textPrimary">
                    <Trans
                        i18nKey="home.intro.message.2"
                        components={[
                            <Link href="https://info.onvaauresto.fr/trouvez-votre-menu/" target="_blank" />,
                        ]}
                    />
                </Typography>
                <Typography component="li" color="textPrimary">
                    <Trans
                        i18nKey="home.intro.message.3"
                        components={[
                            <Link
                                href="https://info.onvaauresto.fr/telecharger-lapplication/"
                                target="_blank"
                            />,
                        ]}
                    />
                </Typography>
                <Typography component="li" color="textPrimary">
                    <Trans i18nKey="home.intro.message.4" />
                </Typography>
            </Typography>
        </div>
    )
}

export default Intro
