import { RestaurantOwnerCheckRequest, RestaurantOwnerCheckResponse } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'

export const useRestaurantOwnerCheck = () => {
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnerCheck = (
        payload: RestaurantOwnerCheckRequest,
    ): Promise<RestaurantOwnerCheckResponse> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerCheck(payload)
            .then(({ data }) => {
                if (data) {
                    return data
                }
                throw generateError()
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        restaurantOwnerCheck,
        inProgress,
    }
}
