import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    useMediaQuery,
} from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { validateAWSEmail } from 'appsync-scalar-utils'
import SubmitButton from 'components/common/submitButton'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import React, { useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import CheckboxField from './components/checkboxField'
import CountryField from './components/countryField'
import PasswordField from './components/passwordField'
import PasswordRules from './components/passwordRules'
import PhoneField from './components/phoneField'
import TextField from './components/textField'
import { useFormsStyles } from './forms.css'
import { useStyles } from './signUpForm.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: SignUpFormState) => void
    inProgress: boolean
    initialValues: Omit<Omit<SignUpFormState, 'password'>, 'passwordConfirmation'>
    onBack: () => void
    establishment?: EstablishmentModel
    resendCode: (establishment: EstablishmentModel) => void
    resendCodeInProgress: boolean
    resendCodeDisabled: boolean
}

export interface SignUpFormState {
    givenName: string
    familyName: string
    email: string
    country: CountryCode
    phone: string
    password: string
    passwordConfirmation: string
    establishment?: string
    code?: string
    remindMe?: Array<boolean>
}

interface SignUpFormError {
    givenName?: string
    familyName?: string
    email?: string
    country?: string
    phone?: string
    password?: string
    passwordConfirmation?: string
    code?: string
}

const SignUpForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    inProgress,
    initialValues: defaultInitialValues,
    onBack,
    establishment,
    resendCode,
    resendCodeInProgress,
    resendCodeDisabled,
}) => {
    const classes = useStyles()
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [showEstablishmentCode, setShowEstablishmentCode] = useState(
        defaultInitialValues.code !== undefined,
    )

    let initialValues: SignUpFormState = {
        ...defaultInitialValues,
        password: '',
        passwordConfirmation: '',
        establishment: establishment?.name,
    }

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: SignUpFormState) => {
        const errors: SignUpFormError = {}
        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }
        if (!values.password) {
            errors.password = t('form-validation.password')
        } else if (!strongPasswordRegex.test(values.password)) {
            errors.password = t('form-validation.passwordStrength')
        }

        if (
            (values.passwordConfirmation || values.password) &&
            values.password !== values.passwordConfirmation
        ) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmationNotMatch')
        }

        if (!values.givenName) {
            errors.givenName = t('form-validation.givenName')
        }
        if (!values.familyName) {
            errors.familyName = t('form-validation.familyName')
        }

        if (values.phone && values.phone !== '') {
            const phone = parsePhoneNumberFromString(values.phone, values.country)
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('form-validation.phone')
            }
        } else {
            errors.phone = t('form-validation.phoneRequired')
        }

        if (establishment && !values.code) {
            errors.code = t('form-validation.establishmentCode')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
            mutators={{
                removeCode: (args, state, tools) => {
                    tools.changeValue(state, 'code', () => undefined)
                },
            }}
        >
            {({ handleSubmit, submitting, form: { mutators } }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <TextField
                        name="email"
                        label={t('form.email')}
                        autoComplete="email"
                        type="email"
                        required
                        disabled
                    />
                    {establishment && (
                        <>
                            <TextField
                                name="establishment"
                                label={t('form.establishmentName')}
                                required
                                disabled
                            />

                            <TextField
                                name="code"
                                label={t('form.establishmentCode')}
                                required
                                autoComplete="one-time-code"
                            />
                            <div className={classes.resendCode}>
                                <SubmitButton
                                    onClick={() => resendCode(establishment)}
                                    loading={resendCodeInProgress}
                                    disabled={resendCodeDisabled}
                                >
                                    <Trans i18nKey="actions.resendEstablishmentCode" />
                                </SubmitButton>
                            </div>
                        </>
                    )}
                    <Grid container spacing={isMobile ? 0 : 6}>
                        <Grid item sm={6} xs={12}>
                            <TextField name="givenName" label={t('form.givenName')} required />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField name="familyName" label={t('form.familyName')} required />
                        </Grid>
                    </Grid>
                    <CountryField label={t('form.country')} name="country" required />
                    <PhoneField label={t('form.phone')} name="phone" required />
                    <div>
                        <PasswordField
                            name="password"
                            label={t('form.password')}
                            required
                            autoComplete="new-password"
                        />
                        <FormSpy subscription={{ values: true }}>
                            {(props) => <PasswordRules value={props.values.password} />}
                        </FormSpy>
                        <PasswordField
                            name="passwordConfirmation"
                            label={t('form.passwordConfirmation')}
                            required
                            autoComplete="new-password"
                        />
                        <CheckboxField
                            name="remindMe"
                            label={t('form.remindMe')}
                            value={true}
                            labelPlacement="end"
                        />
                    </div>
                    {establishment === undefined && (
                        <FormControl margin="normal">
                            <Accordion
                                expanded={showEstablishmentCode}
                                className={classes.establishmentCode}
                                elevation={0}
                            >
                                <AccordionSummary
                                    classes={{
                                        root: classes.establishmentCodeSummary,
                                        content: classes.establishmentCodeLabelContent,
                                    }}
                                >
                                    <FormControlLabel
                                        className={classes.establishmentCodeLabel}
                                        onChange={() => {
                                            setShowEstablishmentCode(!showEstablishmentCode)
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                        }}
                                        onFocus={(event) => {
                                            event.stopPropagation()
                                        }}
                                        control={
                                            <span className={classes.establishmentCodeActions}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => setShowEstablishmentCode(true)}
                                                    color={showEstablishmentCode ? 'primary' : 'default'}
                                                >
                                                    <Trans i18nKey="common.yes" />
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => {
                                                        mutators.removeCode()
                                                        setShowEstablishmentCode(false)
                                                    }}
                                                    color={!showEstablishmentCode ? 'primary' : 'default'}
                                                >
                                                    <Trans i18nKey="common.no" />
                                                </Button>
                                            </span>
                                        }
                                        labelPlacement="start"
                                        label={t('form.linkEstablishment')}
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        name="code"
                                        label={t('form.establishmentCode')}
                                        autoComplete="one-time-code"
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </FormControl>
                    )}
                    <div className={formsClasses.actionsInline}>
                        <Button variant="outlined" disabled={submitting || inProgress} onClick={onBack}>
                            <Trans i18nKey="actions.back" />
                        </Button>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default SignUpForm
