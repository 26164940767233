import React from 'react'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { Button, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useStyles } from './congratulation.css'
import { Trans } from 'react-i18next'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useWebsite } from 'core/services/webssite/useWebsite'
import { Alert } from '@material-ui/lab'

interface Props {
    establishment?: EstablishmentModel
}

const Congratulation: React.FC<Props> = ({ establishment }) => {
    const classes = useStyles()
    const { getUrl } = useWebsite()
    return (
        <div className={classes.root}>
            <CheckCircleIcon color="primary" className={classes.icon} />
            <div>
                <Typography variant="h1" color="primary" className={classes.title}>
                    <Trans i18nKey="establishment.congratulation.title" />
                </Typography>
                <Typography color="primary" className={classes.subTitle}>
                    <Trans i18nKey="establishment.congratulation.subTitle" />
                </Typography>
            </div>
            {establishment?.isDisabled ? (
                <Alert severity="info" className={classes.alert}>
                    <Trans i18nKey="establishment.congratulation.disabled" />
                </Alert>
            ) : (
                <Button
                    variant="outlined"
                    href={establishment ? getUrl(establishment) : undefined}
                    color="primary"
                    endIcon={<OpenInNewIcon />}
                >
                    <Trans i18nKey="establishment.congratulation.action" />
                </Button>
            )}
        </div>
    )
}

export default Congratulation
