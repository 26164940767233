import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 400,
            maxWidth: '100%',
            padding: theme.spacing(1, 1),
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        container: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        logoContainer: {
            'display': 'inline-flex',
            'marginTop': theme.spacing(3),
            'justifyContent': 'center',
            'alignItems': 'center',
            'maxHeight': '100%',
            '& > img': {
                height: '220%',
                [theme.breakpoints.down('sm')]: {
                    height: '160%',
                },
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                padding: theme.spacing(0.5, 0.5),
            },
        },
    }),
)
