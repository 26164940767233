import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import {
    EstablishmentModel,
    RestaurantOwnerModel,
    useRestaurantOwnerUpdateMe,
} from '@sugg-gestion/react-onvaauresto'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import AccountForm, { AccountFormState } from 'components/forms/accountForm'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { CountryCode } from 'libphonenumber-js'
import { usePhoneNumber } from '../../core/services/phone/usePhoneNumber'

interface Props {
    token: string
    restaurantOwner: RestaurantOwnerModel
    establishment?: EstablishmentModel
}

const Account: React.FC<Props> = ({ token, restaurantOwner, establishment }) => {
    const { restaurantOwnerUpdateMe, inProgress } = useRestaurantOwnerUpdateMe()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()
    const { displayPhone } = usePhoneNumber()
    const handleEstablishmentUpdate = (values: AccountFormState) => {
        const updateFields = ['givenName', 'familyName', 'email', 'phone', 'country']
        if (_.isEmpty(values.code)) {
            values.code = undefined
        } else {
            updateFields.push('code')
        }
        if (_.isEmpty(values.password)) {
            values.password = undefined
        } else {
            updateFields.push('password')
        }

        restaurantOwnerUpdateMe(token, restaurantOwner.id, {
            ...values,
            updateFields,
        })
            .then((response) => {
                const { restaurantOwner } = response
                if (!restaurantOwner.hasEmailValidated) {
                    navigate('/account/confirm')
                }
                enqueueSnackbar(t('establishment.update'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }
    return (
        <div>
            <AccountForm
                inProgress={inProgress}
                initialValues={{
                    ...restaurantOwner,
                    country: (restaurantOwner.country as CountryCode) ?? 'FR',
                    phone: displayPhone(restaurantOwner.phone, restaurantOwner?.country as CountryCode) ?? '',
                }}
                onSubmit={handleEstablishmentUpdate}
                disableCode={establishment !== undefined}
            />
        </div>
    )
}

export default withAuthenticateLayout(Account)
