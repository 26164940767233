import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { Trans } from 'react-i18next'
import CloseButton from '../common/closeButton'

interface Props {
    open: boolean
    handleClose: () => void
    email: string
}

const ForgottenPasswordConfirmDialog: React.FC<Props> = ({ open, email, children, handleClose }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Dialog open={open} onClose={handleClose} fullScreen={isMobile} scroll="body">
            <DialogTitle>
                <Trans i18nKey="home.forgotten-password-confirm.title" />
            </DialogTitle>
            <DialogContent>
                <CloseButton handleClose={handleClose} fullScreen={isMobile} />
                <DialogContentText>
                    <Trans i18nKey="home.forgotten-password-confirm.code" values={{ email }} />
                </DialogContentText>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default ForgottenPasswordConfirmDialog
