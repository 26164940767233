import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useFormsStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionsInline: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
            'margin': theme.spacing(6, -1, 0, -1),
            '& a, & button': {
                margin: theme.spacing(0, 1),
            },
        },
        actions: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
            '& a, & button': {
                margin: theme.spacing(0, 1),
            },
            'position': 'fixed',
            'padding': theme.spacing(1.5, 3),
            'bottom': 0,
            'left': 350,
            'right': 0,
            'backgroundColor': theme.palette.background.default,
            'borderTopStyle': 'solid',
            'borderTopWidth': 1,
            'borderTopColor': theme.palette.text.disabled,
            'zIndex': 300,
            [theme.breakpoints.down('sm')]: {
                left: 0,
            },
        },
        actionsInside: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            'alignItems': 'center',
            '& a, & button': {
                margin: theme.spacing(0, 1),
            },
            'padding': theme.spacing(1.5, 0),
            'margin': theme.spacing(4, -1, 0),
            'backgroundColor': theme.palette.background.default,
            [theme.breakpoints.down('sm')]: {
                left: 0,
            },
        },
        submit: {
            minWidth: 200,
        },
        or: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        orDivider: {
            flexGrow: 1,
            backgroundColor: theme.palette.text.primary,
        },
        photoField: {
            height: '100% !important',
        },
        photoFieldContainer: {
            width: '100%',
            height: '100%',
        },
        photoFieldCard: {
            border: '1px solid #EBE6EF',
        },
        photoFieldCardImage: {
            marginTop: theme.spacing(2),
        },
        photoFieldCardWithoutImage: {
            border: '1px solid #F38355',
        },
        photoFieldCardMedia: {
            height: 0,
            paddingTop: '133%',
            backgroundSize: 'contain',
            position: 'relative',
        },
        photoFieldAddContainer: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        photoFieldAdd: {
            fontSize: '6rem',
        },
        helper: {
            fontStyle: 'italic',
            fontSize: '1rem',
            fontWeight: theme.fontsWeight.light,
        },
        shareIcon: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: theme.spacing(),
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
        ovarIcon: {
            margin: 6,
            width: 18,
            height: 18,
        },
        disabled: {
            opacity: 0.5,
        },
        remplisVertContainer: {
            margin: theme.spacing(4, 0, 1),
        },
        remplisVertTitle: {
            padding: theme.spacing(4, 0, 1),
            fontWeight: theme.fontsWeight.semiBold,
            fontSize: '1.2rem',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: theme.palette.text.disabled,
        },
        remplisVert: {
            color: '#218a36',
        },
        remplisVertLink: {
            color: 'inherit',
        },
        remplisVertComment: {
            fontWeight: theme.fontsWeight.semiBold,
        },
    }),
)
