import { RestaurantOwnerResendEstablishmentCodeRequest } from '../../resources/auth/types'
import { useState } from 'react'
import { mutations } from '../../resources'
import { generateError } from '../error'

export const useRestaurantOwnerResendEstablishmentCode = () => {
    const [inProgress, setInProgress] = useState(false)

    const restaurantOwnerResendEstablishmentCode = (
        payload: RestaurantOwnerResendEstablishmentCodeRequest,
    ): Promise<void> => {
        setInProgress(true)
        return mutations
            .restaurantOwnerResendEstablishmentCode(payload)
            .then(() => {})
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        restaurantOwnerResendEstablishmentCode,
        inProgress,
    }
}
