import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
        },
        card: {
            overflow: 'unset',
        },
        intro: {
            marginBottom: theme.spacing(4),
        },
        content: {
            marginTop: theme.spacing(3),
        },
    }),
)
