import React from 'react'
import { Typography } from '@material-ui/core'
import { useStyles } from './confirmationRow.css'
import { useAddress } from 'core/services/address/useAddress'
import { AddressModel } from '@sugg-gestion/react-onvaauresto'

interface Props {
    value?: AddressModel
}

const ConfirmationAddressRow: React.FC<Props> = ({ value }) => {
    const classes = useStyles()
    const { displayAddress } = useAddress()
    return (
        <div className={classes.row}>
            <Typography>{value ? displayAddress(value) : '-'}</Typography>
        </div>
    )
}

export default ConfirmationAddressRow
