import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            padding: theme.spacing(1, 0),
            fontSize: '1.2rem',
            fontWeight: theme.fontsWeight.semiBold,
        },
        inherit: {
            fontSize: 'inherit',
            fontWeight: 'inherit',
        },
        cancel: {
            '& > .MuiTypography-root': {
                textAlign: 'center',
            },
        },
        alert: {
            margin: theme.spacing(1, 0),
        },
    }),
)
