import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import { useStyles } from './websiteIcon.css'

interface Props {
    className?: string
}

const WebsiteIcon: React.FC<SvgIconProps & Props> = (props) => {
    const classes = useStyles()
    return (
        <SvgIcon {...props} viewBox="0 0 512 512" classes={classes}>
            <g>
                <path d="m474.697 39.644h-437.394c-20.569 0-37.303 16.734-37.303 37.303v358.105c0 20.569 16.734 37.304 37.303 37.304h437.395c20.568 0 37.303-16.734 37.303-37.304v-358.105c-.001-20.569-16.735-37.303-37.304-37.303zm22.303 83.356h-379.203c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h379.203v297.053c0 12.299-10.005 22.304-22.303 22.304h-437.394c-12.298-.001-22.303-10.005-22.303-22.304v-297.053h67.737c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-67.737v-46.053c0-12.299 10.005-22.304 22.303-22.304h437.395c12.298 0 22.303 10.005 22.303 22.304v46.053z" />
                <circle cx="65.065" cy="88.822" r="7.477" />
                <circle cx="100.305" cy="88.822" r="7.477" />
                <circle cx="135.545" cy="88.822" r="7.477" />
                <path d="m454.412 81.322h-43.912c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h43.912c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5z" />
                <path d="m196.434 322.891c0 4.09 3.315 7.406 7.406 7.406 4.09 0 7.406-3.316 7.406-7.406s-3.316-7.406-7.406-7.406c-4.091 0-7.406 3.316-7.406 7.406z" />
                <path d="m301.1 322.891c0 4.09 3.316 7.406 7.406 7.406s7.406-3.316 7.406-7.406-3.316-7.406-7.406-7.406-7.406 3.316-7.406 7.406z" />
                <path d="m176.711 274.354-8.969 25.589-8.828-25.879c-.008-.022-.018-.042-.026-.064-2.355-6.7-11.728-6.614-14.115-.085-.007.018-.016.035-.022.054l-9.344 26.219-8.845-25.735c-1.346-3.917-5.607-6.002-9.53-4.655-3.917 1.346-6.002 5.613-4.655 9.53l15.809 46.001c1.036 3.014 3.863 5.044 7.051 5.062h.042c3.17 0 6-1.994 7.064-4.982l9.318-26.146 8.886 26.05c1.032 3.025 3.87 5.064 7.067 5.078h.031c3.185 0 6.023-2.012 7.078-5.02l16.144-46.056c1.37-3.908-.688-8.188-4.598-9.559-3.906-1.367-8.187.688-9.558 4.598z" />
                <path d="m281.081 274.354-8.969 25.589-8.828-25.879c-.008-.022-.018-.042-.026-.064-2.317-6.6-11.688-6.722-14.115-.085-.007.018-.016.035-.022.054l-9.344 26.219-8.844-25.735c-1.346-3.918-5.612-6.003-9.53-4.655-3.917 1.346-6.002 5.613-4.655 9.53l15.808 46.001c1.035 3.014 3.863 5.044 7.05 5.062h.043c3.17 0 6-1.994 7.064-4.982l9.318-26.146 8.887 26.051c1.033 3.025 3.87 5.064 7.067 5.078h.031c3.185 0 6.023-2.012 7.078-5.02l16.143-46.056c1.37-3.908-.688-8.188-4.598-9.559-3.905-1.368-8.187.688-9.558 4.597z" />
                <path d="m385.452 274.354-8.969 25.589-8.828-25.879c-2.326-6.754-11.745-6.701-14.141-.15-.007.018-.016.035-.022.054l-9.345 26.219-8.844-25.735c-1.346-3.918-5.612-6.003-9.53-4.655-3.917 1.346-6.002 5.613-4.655 9.53l15.808 46.001c1.035 3.014 3.863 5.044 7.05 5.062h.043c3.17 0 6-1.993 7.064-4.982l9.318-26.146 8.887 26.051c1.032 3.025 3.87 5.064 7.067 5.078h.031c3.185 0 6.023-2.012 7.078-5.02l16.144-46.056c1.37-3.908-.688-8.188-4.598-9.559-3.906-1.367-8.188.688-9.558 4.598z" />
                <path d="m58.987 253.146v93.084c0 15.536 12.64 28.176 28.177 28.176h52.581c25.496 39.581 69.129 63.471 116.255 63.471 47.081 0 90.735-23.853 116.255-63.471h54.259c15.537 0 28.177-12.64 28.177-28.176v-29.907c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v29.907c0 7.266-5.911 13.176-13.177 13.176h-339.35c-7.266 0-13.177-5.91-13.177-13.176v-93.084c0-7.266 5.911-13.177 13.177-13.177h339.351c7.266 0 13.177 5.911 13.177 13.177v27.854c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-27.854c0-15.537-12.64-28.177-28.177-28.177h-54.26c-25.496-39.579-69.129-63.469-116.255-63.469-47.083 0-90.736 23.855-116.255 63.47h-52.581c-15.537 0-28.177 12.639-28.177 28.176zm264.755 121.26h30.177c-13.303 17.398-31.226 31.394-52.601 39.838 9.845-11.514 17.181-25.626 22.424-39.838zm-60.242 0h44.146c-8.232 20.006-23.065 42.911-44.146 47.63zm-15 0v47.63c-21.094-4.722-35.923-27.648-44.146-47.63zm-60.242 0c5.243 14.21 12.578 28.322 22.424 39.838-21.384-8.447-39.304-22.447-52.601-39.838zm165.654-149.436h-30.17c-5.256-14.246-12.598-28.345-22.423-39.837 21.436 8.468 39.331 22.489 52.593 39.837zm-90.412-47.629c21.094 4.722 35.923 27.648 44.146 47.629h-44.146zm-15 0v47.629h-44.146c8.233-20.006 23.065-42.91 44.146-47.629zm-37.819 7.792c-9.828 11.496-17.169 25.597-22.423 39.837h-30.17c13.26-17.346 31.153-31.367 52.593-39.837z" />
            </g>
        </SvgIcon>
    )
}

export default WebsiteIcon
