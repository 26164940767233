import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(3),
            fontSize: '1rem',
        },
        paragraph: {
            'marginTop': theme.spacing(2),
            'fontSize': '0.9rem',
            '& > li': {
                fontSize: 'inherit',
            },
        },
        underline: {
            textDecoration: 'underline',
        },
        container: {
            position: 'relative',
        },
        card: {
            overflow: 'unset',
        },
        leetchi: {
            position: 'absolute',
            right: 0,
            top: -70,
            textAlign: 'right',
        },
        leetchiLink: {
            display: 'inline-flex',
            alignItems: 'flex-end',
        },
        leetchiLogo: {
            width: 48,
            height: 48,
            marginLeft: theme.spacing(),
            verticalAlign: 'bottom',
        },
    }),
)
