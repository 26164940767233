export interface PermissionModel {
    action: PermissionActions
    subject: PermissionSubjects
    resource: string
}

export enum PermissionActions {
    Manage = 'manage',
    Read = 'read',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Export = 'export',
    Share = 'share',
}

export enum PermissionSubjects {
    Establishment = 'establishment',
    Admin = 'admin',
    RestaurantOwner = 'restaurant-owner',
}
