import React from 'react'
import Loader from 'components/common/loader'
import { useStyles } from './loadingCard.css'

const LoadingCard: React.FC = () => {
    const classes = useStyles()
    return (
        <div className={classes.content}>
            <Loader withLogo={false} />
        </div>
    )
}

export default LoadingCard
