import { combineReducers, Reducer } from 'redux'
import { AppState } from './app/types'
import { appReducer } from './app/reducer'
import { onvaaurestoReducer, OnVaAuRestoState } from '@sugg-gestion/react-onvaauresto'
import { RouterState } from 'redux-first-history'

export interface ApplicationState {
    app: AppState
    onvaauresto: OnVaAuRestoState
    router: RouterState
}

export const createRootReducer = (routerReducer: Reducer) =>
    combineReducers<ApplicationState>({
        app: appReducer,
        onvaauresto: onvaaurestoReducer,
        router: routerReducer,
    })
