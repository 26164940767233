import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import { useStyles } from './websiteIcon.css'

interface Props {
    className?: string
}

const GoogleMyBusinessIcon: React.FC<SvgIconProps & Props> = (props) => {
    const classes = useStyles()
    return (
        <SvgIcon {...props} viewBox="0 0 512 512" classes={classes}>
            <g>
                <path d="m447.648 30h-383.296l-64.352 184.205v17.545c0 24.52 11.793 46.333 30 60.08v190.17h452v-190.17c18.207-13.747 30-35.56 30-60.08v-17.545zm28.223 171.75h-86.764l-24.76-141.75h62.004zm-445.871 30h90.5c0 24.951-20.299 45.25-45.25 45.25s-45.25-20.299-45.25-45.25zm211 0c0 24.951-20.299 45.25-45.25 45.25s-45.25-20.299-45.25-45.25zm-87.653-30 24.76-141.75h62.893v141.75zm117.653 0v-141.75h62.893l24.76 141.75zm90.5 30c0 24.951-20.299 45.25-45.25 45.25s-45.25-20.299-45.25-45.25zm-275.852-171.75h62.004l-24.76 141.75h-86.763zm366.352 392h-392v-146.555c4.926 1.018 10.026 1.555 15.25 1.555 24.619 0 46.513-11.884 60.25-30.216 13.737 18.332 35.631 30.216 60.25 30.216s46.513-11.884 60.25-30.216c13.737 18.332 35.631 30.216 60.25 30.216s46.513-11.884 60.25-30.216c13.737 18.332 35.631 30.216 60.25 30.216 5.224 0 10.324-.537 15.25-1.555zm-15.25-175c-24.951 0-45.25-20.299-45.25-45.25h90.5c0 24.951-20.299 45.25-45.25 45.25z" />
            </g>
        </SvgIcon>
    )
}

export default GoogleMyBusinessIcon
