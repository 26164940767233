import React, { ComponentType, useEffect, useState } from 'react'
import {
    EstablishmentModel,
    RestaurantOwnerModel,
    useEstablishmentGet,
} from '@sugg-gestion/react-onvaauresto'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../core/store/reducers'
import { AnyAbility } from '@casl/ability'

interface InputProps {
    restaurantOwner: RestaurantOwnerModel
    token: string
    ability: AnyAbility
}

interface OutputProps {
    restaurantOwner: RestaurantOwnerModel
    token: string
    establishment?: EstablishmentModel
    ability: AnyAbility
}

const withEstablishment = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithEstablishment: React.FC<InputProps> = ({ restaurantOwner, token, ...props }) => {
        const { establishments } = useSelector(({ onvaauresto: { establishments } }: ApplicationState) => ({
            establishments: establishments.establishments,
        }))
        const establishment = establishments?.find(
            (establishment) => establishment.id === restaurantOwner.establishment,
        )
        const [inProgress, setInProgress] = useState(false)
        const { establishmentGet } = useEstablishmentGet()
        useEffect(() => {
            if (restaurantOwner.establishment && !establishment && !inProgress) {
                setInProgress(true)
                establishmentGet(token, restaurantOwner.establishment)
                    .then(() => {
                        setInProgress(false)
                    })
                    .catch(() => {
                        setInProgress(false)
                    })
            }
        }, [establishment, establishmentGet, inProgress, restaurantOwner.establishment, token])

        return (
            <ComposedComponent
                restaurantOwner={restaurantOwner}
                token={token}
                establishment={establishment}
                {...props}
            />
        )
    }
    return WithEstablishment
}

export default withEstablishment
