import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import EstablishmentDetailsForm, {
    EstablishmentDetailsFormState,
} from 'components/forms/establishmentDetailsForm'
import { EstablishmentModel, useEstablishmentUpdate } from '@sugg-gestion/react-onvaauresto'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { CountryCode } from 'libphonenumber-js'

interface Props {
    token: string
    establishment?: EstablishmentModel
}

const Details: React.FC<Props> = ({ token, establishment }) => {
    const { establishmentUpdate, inProgress } = useEstablishmentUpdate()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()
    const handleEstablishmentUpdate = (values: EstablishmentDetailsFormState) => {
        if (establishment === undefined) {
            return
        }
        establishmentUpdate(token, establishment.id, {
            ...values,
            remplisVertComment: values.remplisVert ? values.remplisVertComment : undefined,
            updateFields: [
                'name',
                'facebookPage',
                'email',
                'phone',
                'delivery',
                'description',
                'remplisVert',
                'remplisVertComment',
            ],
        })
            .then(() => {
                enqueueSnackbar(t('establishment.update'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }
    return (
        <div>
            <EstablishmentDetailsForm
                inProgress={inProgress}
                initialValues={{
                    ...(establishment || {
                        name: '',
                    }),
                    remplisVert: !!(establishment && establishment.remplisVert),
                }}
                onSubmit={handleEstablishmentUpdate}
                country={(establishment?.address.country as CountryCode) ?? 'FR'}
            />
        </div>
    )
}

export default withAuthenticateLayout(Details)
