import { Container, Grid } from '@material-ui/core'
import logo from 'assets/main/ovar2024.png'
import React from 'react'
import { useStyles } from './mainLogo.css'

const MainLogo: React.FC = () => {
    const classes = useStyles()
    return (
        <Container className={classes.container}>
            <Grid container spacing={6} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <img src={logo} alt="OnVaAuResto" width="100%" />
                </Grid>
            </Grid>
        </Container>
    )
}

export default MainLogo
