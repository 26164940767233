import { APIError } from '@sugg-gestion/react-onvaauresto'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export const useApiErrors = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const displayError = (error: APIError) => {
        console.error(error.data)
        switch (error.data) {
            case 'ESTABLISHMENT_LINKED': {
                enqueueSnackbar(t('error.establishmentLinked'), {
                    variant: 'error',
                })
                break
            }
            case 'SHARE_FAILED': {
                enqueueSnackbar(t('error.shareFailed'), {
                    variant: 'error',
                })
                break
            }
            case 'WRONG_CREDENTIALS': {
                enqueueSnackbar(t('error.wrongPassword'), {
                    variant: 'error',
                })
                break
            }
            case 'ESTABLISHMENT_UNIQUE': {
                enqueueSnackbar(t('error.establishmentUnique'), {
                    variant: 'error',
                })
                break
            }
            case 'FILE_UPLOAD_FAILED': {
                enqueueSnackbar(t('error.wrongUpload'), {
                    variant: 'error',
                })
                break
            }
            case 'USER_EXIST': {
                enqueueSnackbar(t('error.emailAlreadyRegistered'), {
                    variant: 'error',
                })
                break
            }
            case 'WRONG_CODE': {
                enqueueSnackbar(t('error.code'), {
                    variant: 'error',
                })
                break
            }
            case 'WRONG_VALIDATION_STATE': {
                enqueueSnackbar(t('error.wrongValidationState'), {
                    variant: 'warning',
                })
                break
            }
            default: {
                enqueueSnackbar(t('error.unexpected'), {
                    variant: 'error',
                })
            }
        }
    }

    return {
        displayError,
    }
}
