import { applyMiddleware, compose, Middleware } from 'redux'
import loggerMiddleware from 'redux-logger'

export const createMiddleware = (routerMiddleware: Middleware, sagaMiddleware: Middleware) => {
    let middlewares: Array<Middleware> = []
    middlewares = [...middlewares, sagaMiddleware]
    middlewares = [...middlewares, routerMiddleware]
    if (process.env.REACT_APP_LOGGER === 'on') {
        middlewares = [...middlewares, loggerMiddleware]
    }
    return compose(applyMiddleware(...middlewares))
}
