import React from 'react'
import { EstablishmentFormState } from 'containers/establishment/establishment'
import ConfirmationTitleRow from './components/confirmationTitleRow'
import ConfirmationRow from './components/confirmationRow'
import ConfirmationAddressRow from './components/confirmationAddressRow'
import ConfirmationPhotoRow from './components/confirmationPhotoRow'
import { Trans } from 'react-i18next'
import SubmitButton from '../../common/submitButton'
import { useFormsStyles } from '../../forms/forms.css'

interface Props {
    state: EstablishmentFormState
    inProgress: boolean
    onSubmit: () => void
    onEdit: (step: number) => void
}

const Confirmation: React.FC<Props> = ({ state, inProgress, onSubmit, onEdit }) => {
    const formsClasses = useFormsStyles()
    return (
        <div>
            <ConfirmationTitleRow title="form.establishment" disabled={inProgress} onEdit={() => onEdit(0)} />
            <ConfirmationRow title="form.establishmentName" value={state.name} />
            <ConfirmationRow title="form.establishmentEmail" value={state.email} />
            <ConfirmationRow title="form.establishmentFacebookPage" value={state.facebookPage} />
            <ConfirmationRow title="form.establishmentPhone" value={state.phone} />
            <ConfirmationRow title="form.establishmentDelivery" value={state.delivery} />
            <ConfirmationTitleRow title="form.address" disabled={inProgress} onEdit={() => onEdit(1)} />
            <ConfirmationAddressRow value={state.address} />
            <ConfirmationTitleRow title="form.photo" disabled={inProgress} onEdit={() => onEdit(2)} />
            <ConfirmationPhotoRow url={state.url} image={state.image} />

            <div className={formsClasses.actions}>
                <SubmitButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={inProgress}
                    className={formsClasses.submit}
                    onClick={onSubmit}
                >
                    <Trans i18nKey="actions.validate" />
                </SubmitButton>
            </div>
        </div>
    )
}

export default Confirmation
