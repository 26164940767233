import React from 'react'
import { Card, CardContent, Container, Link, Typography } from '@material-ui/core'
import { useStyles } from './notFoundView.css'
import { Trans } from 'react-i18next'
import MainLayout from 'components/layouts/mainLayout'

const NotFoundView: React.FC = () => {
    const classes = useStyles()
    return (
        <MainLayout>
            <Container fixed maxWidth="sm">
                <Card>
                    <CardContent className={classes.card}>
                        <Typography className={classes.title} variant="h1">
                            <Trans i18nKey="not-found.title" />
                        </Typography>
                        <Typography className={classes.subTitle} variant="h2">
                            <Trans i18nKey="not-found.subTitle" />
                        </Typography>
                        <div className={classes.action}>
                            <Link href="/">
                                <Trans i18nKey="not-found.action" />
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </MainLayout>
    )
}

export default NotFoundView
