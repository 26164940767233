import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        establishmentCode: {
            'borderWidth': 0,
            'borderBottomWidth': 1,
            'borderStyle': 'solid',
            'borderColor': theme.palette.text.disabled,
            '&:before': {
                height: 0,
            },
            '&.Mui-expanded': {
                margin: 0,
            },
        },
        establishmentCodeLabelContent: {
            '&.Mui-expanded': {
                margin: theme.spacing(1.5, 0),
            },
        },
        establishmentCodeLabel: {
            'marginLeft': 0,
            '& > .MuiTypography-root': {
                fontWeight: theme.fontsWeight.semiBold,
            },
        },
        establishmentCodeSummary: {
            paddingLeft: 0,
        },
        establishmentCodeActions: {
            'paddingLeft': 8,
            'margin': theme.spacing(0, -0.5),
            'display': 'inline-flex',
            '& > .MuiButton-root': {
                margin: theme.spacing(0, 0.5),
            },
        },
        resendCode: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            '& .MuiButton-root': {
                textTransform: 'none',
                fontSize: '1rem',
                textDecoration: 'underline',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '0.8rem',
                },
            },
        },
    }),
)
