import { useEstablishmentUpdate } from '@sugg-gestion/react-onvaauresto'
import LoadingDialog from 'components/dialogs/loadingDialog'
import EstablishmentPhotoForm, { EstablishmentPhotoFormState } from 'components/forms/establishmentPhotoForm'
import ShareLocation from 'components/views/establishment/components/shareLocation'
import withAuthenticateLayout, { WithAuthenticateLayoutProps } from 'containers/hoc/withAuthenticateLayout'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Advantage from '../../components/views/establishment/components/advantages'

interface Props extends WithAuthenticateLayoutProps {}

const MenuReplace: React.FC<Props> = ({ token, establishment }) => {
    const { establishmentUpdate, inProgress } = useEstablishmentUpdate()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()

    const handleEstablishmentUpdate = (values: EstablishmentPhotoFormState) => {
        if (establishment === undefined) {
            return
        }
        establishmentUpdate(token, establishment.id, {
            ...values,
            image: values.image?.file,
            updateFields: ['photo'],
        })
            .then(() => {
                enqueueSnackbar(t('establishment.update'), {
                    variant: 'success',
                })
            })
            .then(() => {
                navigate('/establishment/menu')
            })
            .catch((error) => {
                displayError(error)
            })
    }
    const initialValues: EstablishmentPhotoFormState = {
        isFacebookShared: true,
    }
    return (
        <div>
            <EstablishmentPhotoForm
                inProgress={inProgress}
                initialValues={initialValues}
                onSubmit={handleEstablishmentUpdate}
                isPhotoShared={false}
                showPhotoShare={false}
                forceFacebook
            />
            <ShareLocation establishment={establishment} />
            <Advantage />
            <LoadingDialog open={inProgress} loadingMessage={t('actions.saveInProgress')} />
        </div>
    )
}

export default withAuthenticateLayout(MenuReplace)
