import { Button, FormControl, Grid, Link, Typography } from '@material-ui/core'
import HiddenJs from '@material-ui/core/Hidden/HiddenJs'
import { validateAWSEmail, validateAWSURL } from 'appsync-scalar-utils'
import remplisVert from 'assets/main/remplisVertFull.png'
import SubmitButton from 'components/common/submitButton'
import { characterTextLength } from 'components/helpers/textLength'
import { usePhoneNumber } from 'core/services/phone/usePhoneNumber'
import { isValidUrl } from 'core/services/url/isValidUrl'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import CheckboxField from './components/checkboxField'
import PhoneField from './components/phoneField'
import TextField from './components/textField'
import TextFieldLength from './components/textFieldLength'
import { useFormsStyles } from './forms.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EstablishmentDetailsFormState) => void
    initialValues: EstablishmentDetailsFormState
    inProgress: boolean
    onBack?: () => void
    country?: CountryCode
}

export interface EstablishmentDetailsFormState {
    name: string
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
    remplisVert: boolean
    remplisVertComment?: string
}

export interface EstablishmentDetailsFormStateInternal {
    name: string
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
    remplisVert: Array<boolean>
    remplisVertComment?: string
}

export interface EstablishmentDetailsFormError {
    name?: string
    facebookPage?: string
    email?: string
    phone?: string
    delivery?: string
    description?: string
}

const EstablishmentDetailsForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        errors: true,
    },
    onSubmit,
    initialValues,
    inProgress,
    onBack,
    country,
}) => {
    const { t } = useTranslation()
    const formsClasses = useFormsStyles()
    const { displayPhone } = usePhoneNumber()
    const { enqueueSnackbar } = useSnackbar()

    const initialValuesInternal: EstablishmentDetailsFormStateInternal = {
        name: initialValues.name,
        facebookPage: initialValues.facebookPage,
        email: initialValues.email,
        phone: displayPhone(initialValues.phone, country),
        delivery: initialValues.delivery,
        description: initialValues.description,
        remplisVert: initialValues.remplisVert ? [true] : [],
        remplisVertComment: initialValues.remplisVertComment,
    }

    const validate = (values: EstablishmentDetailsFormStateInternal) => {
        const errors: EstablishmentDetailsFormError = {}
        if (!values.name) {
            errors.name = t('form-validation.establishmentName')
        }

        if (!values.email) {
            errors.email = t('form-validation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('form-validation.emailValid')
        }

        if (!values.phone) {
            errors.phone = t('form-validation.phoneRequired')
        } else {
            const phone = parsePhoneNumberFromString(values.phone, country)
            if (phone === undefined || !phone.isValid()) {
                errors.phone = t('form-validation.phone')
            }
        }

        if (
            values.facebookPage &&
            !(isValidUrl(values.facebookPage) && validateAWSURL(values.facebookPage))
        ) {
            errors.facebookPage = t('form-validation.url')
        }

        if (values.delivery && !(isValidUrl(values.delivery) && validateAWSURL(values.delivery))) {
            errors.delivery = t('form-validation.url')
        }

        if (!values.description) {
            errors.description = t('form-validation.descriptionLength')
        } else if (values.description && characterTextLength(values.description) < 50) {
            errors.description = t('form-validation.descriptionLength')
        }

        return errors
    }

    const handleSubmit = (values: EstablishmentDetailsFormStateInternal) => {
        return onSubmit({
            ...values,
            remplisVert: values.remplisVert && values.remplisVert.length > 0,
        })
    }

    return (
        <Form
            initialValues={initialValuesInternal}
            subscription={subscription}
            // debug={console.log}
            onSubmit={handleSubmit}
            validate={validate}
            mutators={{
                changeRemplisVertComment: (args, state, tools) => {
                    const newRemplisVertComment = args[0] as string | undefined
                    if (state.lastFormState && newRemplisVertComment) {
                        const { remplisVertComment } = state.lastFormState.values
                        if (
                            remplisVertComment === undefined ||
                            newRemplisVertComment?.length > remplisVertComment?.length
                        ) {
                            tools.changeValue(state, 'remplisVert', () => [true])
                        }
                    }
                },
            }}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, errors, form: { mutators } }) => {
                const submit = (event: any) => {
                    handleSubmit(event)
                    if (!_.isEmpty(errors)) {
                        enqueueSnackbar(t('error.validation'), {
                            variant: 'error',
                        })
                    }
                }
                return (
                    <form noValidate>
                        <Typography variant="h1" color="textSecondary">
                            <Trans i18nKey="form.establishment" />
                        </Typography>
                        <TextField
                            name="name"
                            label={t('form.establishmentName')}
                            autoComplete="organization"
                            required
                        />
                        <TextField
                            name="email"
                            label={t('form.establishmentEmail')}
                            autoComplete="email"
                            type="email"
                            required
                        />
                        <PhoneField name="phone" label={t('form.establishmentPhone')} required />
                        <TextFieldLength
                            name="description"
                            label={t('form.establishmentDescription')}
                            helperText={t('form.establishmentDescriptionHelper')}
                            multiline
                            minRows={3}
                            maxRows={6}
                            calculateFunction={characterTextLength}
                            minLength={50}
                            required
                        />
                        <TextField name="facebookPage" label={t('form.establishmentFacebookPage')} />
                        <TextField name="delivery" label={t('form.establishmentDelivery')} />
                        <Grid container className={formsClasses.remplisVertContainer}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h1"
                                    color="textSecondary"
                                    className={formsClasses.remplisVertTitle}
                                >
                                    <Trans
                                        i18nKey="form.remplisVertTitle"
                                        components={[
                                            <span className={formsClasses.remplisVert} />,
                                            <Link
                                                className={formsClasses.remplisVertLink}
                                                href="https://info.onvaauresto.fr/remplisvert/"
                                                target="_blank"
                                                rel="noreferrer"
                                            />,
                                        ]}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item sm={8} xs={12} style={{ maxWidth: 860 }}>
                                <CheckboxField
                                    name="remplisVert"
                                    value={true}
                                    labelPlacement="end"
                                    label={
                                        <Trans
                                            i18nKey="form.remplisVert"
                                            components={[
                                                <span className={formsClasses.remplisVert} />,
                                                <Link
                                                    className={formsClasses.remplisVertLink}
                                                    href="https://info.onvaauresto.fr/remplisvert/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                />,
                                            ]}
                                        />
                                    }
                                    helper={
                                        <Trans
                                            i18nKey="form.remplisVertHelper"
                                            components={[<span className={formsClasses.remplisVert} />]}
                                        />
                                    }
                                />
                                <FormControl fullWidth margin="none">
                                    <Typography component="label" htmlFor="remplisVertComment">
                                        <Trans
                                            i18nKey="form.remplisVertComment"
                                            components={[
                                                <span className={formsClasses.remplisVertComment} />,
                                            ]}
                                        />
                                    </Typography>
                                    <TextField
                                        margin="dense"
                                        variant="outlined"
                                        name="remplisVertComment"
                                        multiline
                                        onChange={(e) => {
                                            mutators.changeRemplisVertComment(e.currentTarget.value)
                                        }}
                                        minRows={3}
                                        maxRows={6}
                                        inputProps={{
                                            id: 'remplisVertComment',
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <HiddenJs xsDown>
                                <Grid
                                    item
                                    sm={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        padding: 32,
                                    }}
                                >
                                    <Link
                                        className={formsClasses.remplisVertLink}
                                        href="https://info.onvaauresto.fr/remplisvert/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={remplisVert}
                                            alt="Remplis Vert"
                                            style={{ width: '100%', maxWidth: 220 }}
                                        />
                                    </Link>
                                </Grid>
                            </HiddenJs>
                        </Grid>

                        <div className={formsClasses.actions}>
                            {onBack!! && (
                                <Button variant="contained" onClick={onBack}>
                                    <Trans i18nKey="actions.back" />
                                </Button>
                            )}
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={submitting || inProgress}
                                className={formsClasses.submit}
                                onClick={submit}
                            >
                                <Trans i18nKey="actions.validate" />
                            </SubmitButton>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}

export default EstablishmentDetailsForm
