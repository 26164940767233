import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = (isMobile: boolean) =>
    makeStyles((theme: Theme) => {
        let drawerWidth = 350
        if (isMobile) {
            drawerWidth = 286
        }
        return createStyles({
            root: {
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
            },
            drawer: {
                [theme.breakpoints.up('md')]: {
                    width: drawerWidth,
                    flexShrink: 0,
                },
            },
            appBar: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
                backgroundColor: '#fff',
                borderBottom: '1px solid #DCDCDC',
            },
            logo: {
                height: 30,
            },
            menuButton: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            headerRight: {
                width: 48,
                marginLeft: theme.spacing(2),
            },
            largeIconRight: {
                marginLeft: -11,
            },
            // necessary for content to be below app bar
            toolbar: {
                ...theme.mixins.toolbar,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FAFAFA',
                borderBottom: '1px solid #DCDCDC',
            },
            toolbarSpacing: theme.mixins.toolbar,
            logoContainer: {
                height: 64,
                width: '100%',
                [theme.breakpoints.down('xs')]: {
                    'height': 56,
                    'padding': theme.spacing(0.5),
                    '@media (orientation: landscape)': {
                        height: 48,
                    },
                },
            },
            drawerPaper: {
                width: drawerWidth,
                maxWidth: '90%',
                backgroundColor: '#F0F0F0',
                borderRight: 'none',
            },
            contentContainer: {
                flexGrow: 1,
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(3),
                paddingBottom: 160,
                backgroundColor: theme.palette.background.default,
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(2),
                },
            },
            listItem: {
                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },
            toolbarAside: {
                color: theme.palette.text.secondary,
            },
        })
    })
