import { Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './card.css'
import clsx from 'clsx'

const SignInCard: React.FC = ({ children }) => {
    const classes = useStyles()
    return (
        <>
            <Typography color="primary" className={clsx(classes.title)}>
                <Trans i18nKey="home.sign-in.title" />
            </Typography>
            <Typography color="textPrimary">
                <Trans i18nKey="home.sign-in.message" />
            </Typography>
            {children}
        </>
    )
}

export default SignInCard
