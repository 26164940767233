import React, { ComponentType } from 'react'
import AuthenticateLayout from 'components/layouts/authenticateLayout'
import { useDispatch } from 'react-redux'
import { actions, EstablishmentModel, RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import withAuthentication from './withAuthentication'
import withEstablishment from './withEstablishment'
import { AnyAbility } from '@casl/ability'

export interface WithAuthenticateLayoutProps {
    restaurantOwner: RestaurantOwnerModel
    establishment?: EstablishmentModel
    ability: AnyAbility
    token: string
}

const withAuthenticateLayout = (ComposedComponent: ComponentType<WithAuthenticateLayoutProps>) => {
    const WithAuthenticateLayout: React.FC<WithAuthenticateLayoutProps> = ({
        restaurantOwner,
        establishment,
        ability,
        ...props
    }) => {
        const dispatch = useDispatch()
        const { enqueueSnackbar } = useSnackbar()
        const { t } = useTranslation()
        const signOut = () => {
            dispatch(actions.restaurantOwnerSignOut())
            enqueueSnackbar(t('sign-out.success'), {
                variant: 'success',
            })
        }

        return (
            <AuthenticateLayout
                signOut={signOut}
                restaurantOwner={restaurantOwner}
                establishment={establishment}
                ability={ability}
            >
                <ComposedComponent
                    {...props}
                    restaurantOwner={restaurantOwner}
                    establishment={establishment}
                    ability={ability}
                />
            </AuthenticateLayout>
        )
    }
    return withAuthentication(withEstablishment(WithAuthenticateLayout))
}

export default withAuthenticateLayout
