import SubmitButton from 'components/common/submitButton'
import React from 'react'
import { Form } from 'react-final-form'
import { useFormsStyles } from './forms.css'
import { Trans } from 'react-i18next'
import CodeField from './components/codeField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: RestaurantOwnerConfirmFormState) => void
    initialValues: RestaurantOwnerConfirmFormState
    inProgress: boolean
    children?: (inProgress: boolean) => React.ReactNode
    actions?: (inProgress: boolean) => React.ReactNode
}

export interface RestaurantOwnerConfirmFormState {
    code: string
}

const RestaurantOwnerConfirmForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    initialValues,
    inProgress,
    children,
    actions,
}) => {
    const formsClasses = useFormsStyles()
    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        <CodeField
                            name="code"
                            onChange={(value) => {
                                if (value.length === 6) {
                                    handleSubmit()
                                }
                            }}
                        />
                    </div>
                    {children && children(submitting || inProgress)}
                    <div className={formsClasses.actionsInside}>
                        {actions && actions(submitting || inProgress)}
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}
export default RestaurantOwnerConfirmForm
