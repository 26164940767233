import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React from 'react'
import { useStyles } from './websiteIcon.css'

interface Props {
    className?: string
}

const StudentIcon: React.FC<SvgIconProps & Props> = (props) => {
    const classes = useStyles()
    return (
        <SvgIcon {...props} viewBox="0.000000 0.000000 1079.472534 631.020386" classes={classes}>
            <g>
                <path d="M1079.5 207.7 C907.5 270.8 733.2 327.8 557.1 378.4 C537.2 384.1 516.0 377.9 496.3 371.7 C394.3 339.9 292.4 308.1 190.4 276.3 C214.1 273.4 236.8 262.5 253.9 245.9 C233.6 249.5 213.4 253.2 193.1 256.8 C188.8 257.6 184.1 258.5 181.1 261.7 C177.5 265.3 177.1 270.8 176.8 275.9 C173.1 347.1 169.3 418.3 165.6 489.6 C164.6 506.9 148.0 520.2 130.8 523.2 C113.7 526.2 96.2 521.4 79.5 516.7 C73.5 515.0 67.1 513.0 63.4 508.1 C59.3 502.8 59.4 495.5 59.9 488.8 C65.3 414.6 93.4 344.1 121.4 275.1 C130.6 252.2 148.5 224.6 172.5 230.8 C154.7 217.8 126.0 224.1 115.5 243.5 C113.5 247.2 108.1 246.3 104.1 244.9 C69.4 232.4 34.7 219.9 0.0 207.4 C175.1 140.1 350.2 72.7 525.4 5.3 C539.0 0.0 554.2 5.3 567.9 10.5 C738.4 76.3 908.9 142.0 1079.5 207.7 " />
                <path d="M689.1 609.4 C600.6 629.0 508.4 631.0 419.2 615.5 C366.6 606.3 314.3 590.7 269.5 561.7 C248.8 548.3 229.0 530.8 221.6 507.3 C217.5 494.4 217.5 480.6 217.5 467.0 C217.6 418.6 217.7 370.2 217.8 321.7 C322.6 353.9 427.4 386.2 532.2 418.4 C539.7 420.7 547.8 418.4 555.3 416.0 C657.3 384.8 759.3 353.5 861.3 322.2 C861.5 369.9 861.7 417.7 861.9 465.5 C862.0 479.7 862.0 494.3 857.6 507.9 C850.7 529.3 833.6 545.9 815.2 558.8 C777.9 585.0 733.6 599.6 689.1 609.4 " />
            </g>
        </SvgIcon>
    )
}

export default StudentIcon
