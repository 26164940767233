import React, { ComponentType, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { useNavigate } from 'react-router-dom'
import Loader from 'components/common/loader'
import { RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'
import { AnyAbility } from '@casl/ability'

interface Props {
    restaurantOwner: RestaurantOwnerModel
    token: string
    ability: AnyAbility
}

const withAuthentication = (ComposedComponent: ComponentType<Props>) => {
    const WithAuthentication: React.FC = (props) => {
        const navigate = useNavigate()
        const { restaurantOwner, token, ability } = useSelector(
            ({ onvaauresto: { auth }, app }: ApplicationState) => ({
                restaurantOwner: auth.restaurantOwner,
                token: auth.token,
                ability: app.ability,
            }),
        )
        useEffect(() => {
            if (!restaurantOwner) {
                navigate('/')
            }
        }, [restaurantOwner, navigate])
        if (restaurantOwner && token) {
            return (
                <ComposedComponent
                    restaurantOwner={restaurantOwner}
                    token={token}
                    ability={ability}
                    {...props}
                />
            )
        } else {
            return <Loader />
        }
    }
    return WithAuthentication
}

export default withAuthentication
