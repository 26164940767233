import { AnyAbility } from '@casl/ability'
import { useMediaQuery } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { Alert } from '@material-ui/lab'
import { EstablishmentModel, RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './authenticateLayout.css'
import AuthenticateDrawer from './components/authenticateDrawer'

interface Props {
    restaurantOwner: RestaurantOwnerModel
    signOut: () => void
    establishment?: EstablishmentModel
    ability: AnyAbility
}

const AuthenticateLayout: React.FC<Props> = ({
    signOut,
    restaurantOwner,
    establishment,
    ability,
    children,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const classes = useStyles(isMobile)()
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar className={classes.toolbarAside}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <AuthenticateDrawer
                            signOut={signOut}
                            restaurantOwner={restaurantOwner}
                            establishment={establishment}
                            ability={ability}
                        />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        elevation={0}
                        open
                    >
                        <AuthenticateDrawer
                            signOut={signOut}
                            restaurantOwner={restaurantOwner}
                            establishment={establishment}
                            ability={ability}
                        />
                    </Drawer>
                </Hidden>
            </nav>
            <div className={classes.contentContainer}>
                <div className={classes.toolbarSpacing} />
                {establishment?.isDisabled && (
                    <Alert severity="info">
                        <Trans i18nKey="establishment.disabled" />
                    </Alert>
                )}
                <main className={classes.content}>{children}</main>
            </div>
        </div>
    )
}

export default AuthenticateLayout
