import { Button, Divider, Grid, ListSubheader, Switch, Typography } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button/Button'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Skeleton } from '@material-ui/lab'
import { EstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import appOvar from 'assets/main/ovarFull-120.png'
import GoogleIcon from 'components/icons/googleIcon'
import WebsiteIcon from 'components/icons/websiteIcon'
import { useWebsite } from 'core/services/webssite/useWebsite'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './shareLocation.css'

interface Props {
    establishment?: EstablishmentModel
    title?: string
    withoutEstablishment?: boolean
}

interface PropsShareLocationListItem {
    title: string
    action?: string
    icon: React.ReactNode
    actionIcon?: React.ReactNode
    checked: boolean
    href?: string
    buttonProps?: ButtonProps<'a'>
}

const ShareLocationListItem: React.FC<PropsShareLocationListItem> = ({
    title,
    icon,
    actionIcon,
    checked,
    href,
    action,
    buttonProps,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="flex-end" className={classes.item}>
                <Grid item className={classes.titleContainer}>
                    <Grid container alignItems="center" className={classes.itemTitle}>
                        <Grid item className={classes.shareIcon}>
                            <Switch color="primary" edge="start" checked={checked} disableRipple />
                            {icon}
                        </Grid>
                        <Grid item>
                            <Typography>{t(title)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {action && href && (
                    <Grid item>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            href={href}
                            startIcon={actionIcon}
                            endIcon={<OpenInNewIcon />}
                            target="_blank"
                            {...buttonProps}
                        >
                            <Trans i18nKey={action} />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider />
        </Grid>
    )
}

const ShareLocation: React.FC<Props> = ({
    establishment,
    title = 'establishment.share.on',
    withoutEstablishment = false,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { getUrl } = useWebsite()

    return (
        <Grid container>
            <Grid item xs={12}>
                <ListSubheader className={classes.title}>
                    <Trans i18nKey={title} />
                </ListSubheader>
            </Grid>
            {withoutEstablishment ? (
                <ShareLocationListItem
                    checked
                    title={t('establishment.share.onOvar')}
                    icon={
                        <span className={classes.icon}>
                            <WebsiteIcon fontSize="large" />
                        </span>
                    }
                    actionIcon={<WebsiteIcon />}
                />
            ) : (
                <>
                    {establishment === undefined && (
                        <Skeleton variant="rect" width="100%">
                            <ShareLocationListItem checked title="" icon={null} />
                        </Skeleton>
                    )}
                    {establishment && (
                        <ShareLocationListItem
                            checked
                            title={t('establishment.share.onOvar')}
                            icon={
                                <span className={classes.icon}>
                                    <img
                                        src={appOvar}
                                        alt="On va au resto"
                                        className={classes.imageIconLarge}
                                    />
                                </span>
                            }
                            href={getUrl(establishment)}
                            action={establishment.isDisabled ? undefined : 'actions.accessPage'}
                            actionIcon={
                                <img src={appOvar} alt="On va au resto" className={classes.imageIcon} />
                            }
                        />
                    )}
                </>
            )}
            <ShareLocationListItem
                checked
                title={t('establishment.share.onSugg1144')}
                icon={
                    <span className={classes.icon}>
                        <img src={appOvar} alt="On va au resto" className={classes.imageIconLarge} />
                    </span>
                }
                href="https://bit.ly/MonResto"
                action="actions.accessApplication"
                actionIcon={<img src={appOvar} alt="On va au resto" className={classes.imageIcon} />}
            />

            <Grid item xs={12} className={classes.suggProList}>
                <div className={classes.suggProOverlay}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Switch color="primary" edge="start" checked={false} disableRipple />}
                        endIcon={<OpenInNewIcon />}
                        href="https://resto.suggpro.com/comment-passer-a-suggpro"
                        target="_blank"
                    >
                        <Trans i18nKey="actions.shareSuggPro" />
                    </Button>
                </div>
                <ShareLocationListItem
                    checked={false}
                    title={t('establishment.share.onYourFacebook')}
                    icon={<FacebookIcon fontSize="large" className={classes.facebook} />}
                />
                <ShareLocationListItem
                    checked={false}
                    title={t('establishment.share.onYourInstagram')}
                    icon={<InstagramIcon fontSize="large" className={classes.instagram} />}
                />
                <ShareLocationListItem
                    checked={false}
                    title={t('establishment.share.onYourGoogleMyBusiness')}
                    icon={<GoogleIcon fontSize="large" />}
                />
                <ShareLocationListItem
                    checked={false}
                    title={t('establishment.share.withNotification')}
                    icon={<NotificationsActiveIcon color="primary" fontSize="large" />}
                />
            </Grid>
        </Grid>
    )
}

export default ShareLocation
