import { Button, Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './card.css'
import clsx from 'clsx'
import { Alert } from '@material-ui/lab'

interface Props {
    fromHome: boolean
    onBack: () => void
}

const SignUpCard: React.FC<Props> = ({ children, fromHome, onBack }) => {
    const classes = useStyles()
    return (
        <>
            <Typography color="primary" className={clsx(classes.title)}>
                <Trans i18nKey="home.sign-up.title" />
            </Typography>
            {fromHome && (
                <Alert
                    className={classes.alert}
                    severity="info"
                    action={
                        <Button variant="outlined" color="inherit" size="small" onClick={onBack}>
                            <Trans i18nKey="actions.back" />
                        </Button>
                    }
                >
                    <Trans i18nKey="home.sign-up.fromHome" />
                </Alert>
            )}
            <Typography color="textPrimary">
                <Trans i18nKey="home.sign-up.message" />
            </Typography>
            {children}
        </>
    )
}

export default SignUpCard
