import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            marginBottom: theme.spacing(),
        },
        title: {
            fontWeight: theme.fontsWeight.semiBold,
        },
        photoFieldCard: {
            border: '1px solid #EBE6EF',
            maxWidth: 390,
        },
        photoFieldCardMedia: {
            height: 0,
            paddingTop: '133%',
            backgroundSize: 'contain',
            position: 'relative',
        },
    }),
)
