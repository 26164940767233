import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            'minHeight': 300,
            'display': 'inline-flex',
            'justifyContent': 'flex-start',
            'alignItems': 'flex-start',
            '& > img': {
                width: '100%',
                height: '100%',
                margin: theme.spacing(),
            },
        },
        facebookIcon: {
            color: theme.socialColors.facebook,
        },
        action: {
            [theme.breakpoints.down('md')]: {
                position: 'static',
                top: 'inherit',
                right: 'inherit',
                transform: 'inherit',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.spacing(0.5),
            },
        },
        actions: {
            // marginTop: theme.spacing(6),
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(1),
            },
        },
        loader: {
            justifyContent: 'center',
        },
    }),
)
