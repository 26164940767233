import React from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import { EstablishmentModel, useEstablishmentUpdate } from '@sugg-gestion/react-onvaauresto'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import EstablishmentAddressForm, {
    EstablishmentAddressFormState,
} from 'components/forms/establishmentAddressForm'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

interface Props {
    token: string
    establishment?: EstablishmentModel
}

const Address: React.FC<Props> = ({ token, establishment }) => {
    const { establishmentUpdate, inProgress } = useEstablishmentUpdate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()
    const handleEstablishmentUpdate = (values: EstablishmentAddressFormState) => {
        if (establishment === undefined) {
            return
        }
        establishmentUpdate(token, establishment.id, {
            ...values,
            updateFields: ['address', 'location'],
        })
            .then(() => {
                enqueueSnackbar(t('establishment.update'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }
    return (
        <div>
            <EstablishmentAddressForm
                inProgress={inProgress}
                initialValues={{
                    ...(establishment || {
                        address: {
                            line1: '',
                            city: '',
                            zipCode: '',
                            country: 'FR',
                        },
                        location: {
                            latitude: 46.7111,
                            longitude: 1.7191,
                        },
                    }),
                }}
                onSubmit={handleEstablishmentUpdate}
                isMobile={isMobile}
            />
        </div>
    )
}

export default withAuthenticateLayout(Address)
