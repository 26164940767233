import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cguLink: {
            textAlign: 'center',
            padding: theme.spacing(1, 3),
            fontSize: '0.8rem',
            width: 'auto',
        },
    }),
)
