import React, { useState } from 'react'
import withAuthenticateLayout from 'containers/hoc/withAuthenticateLayout'
import {
    RestaurantOwnerModel,
    useRestaurantOwnerConfirm,
    useRestaurantOwnerResend,
} from '@sugg-gestion/react-onvaauresto'
import { useSnackbar } from 'notistack'
import { Trans, useTranslation } from 'react-i18next'
import { useApiErrors } from 'core/services/onvaauresto/useApiErrors'
import ContainerLeft from 'components/common/containerLeft'
import { Button, Typography } from '@material-ui/core'
import RestaurantOwnerConfirmForm, {
    RestaurantOwnerConfirmFormState,
} from 'components/forms/restaurantOwnerConfirmForm'
import Resend from 'components/views/account/resend'
import { useNavigate } from 'react-router-dom'

interface Props {
    restaurantOwner: RestaurantOwnerModel
    token: string
}

const Confirm: React.FC<Props> = ({ token, restaurantOwner }) => {
    const navigate = useNavigate()
    const { restaurantOwnerConfirm, inProgress: restaurantOwnerConfirmInProgress } =
        useRestaurantOwnerConfirm()
    const { restaurantOwnerResend, inProgress: restaurantOwnerResendInProgress } = useRestaurantOwnerResend()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { displayError } = useApiErrors()
    const [resendCode, setResendCode] = useState(false)

    const onSubmit = (values: RestaurantOwnerConfirmFormState) => {
        restaurantOwnerConfirm(token, values)
            .then((response) => {
                if (response.restaurantOwner.establishment) {
                    navigate('/establishment/menu')
                } else {
                    navigate('/establishment')
                }
                enqueueSnackbar(t('email-validation.success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const onResend = () => {
        restaurantOwnerResend(token)
            .then(() => {
                setResendCode(true)
                enqueueSnackbar(t('email-validation.resend-success'), {
                    variant: 'success',
                })
            })
            .catch((error) => {
                displayError(error)
            })
    }

    return (
        <ContainerLeft maxWidth="sm">
            <Typography variant="h1" color="textSecondary">
                <Trans i18nKey="email-validation.title" />
            </Typography>
            <Typography>
                <Trans i18nKey="email-validation.description" values={{ email: restaurantOwner.email }} />
            </Typography>
            <RestaurantOwnerConfirmForm
                initialValues={{
                    code: '',
                }}
                onSubmit={onSubmit}
                inProgress={restaurantOwnerConfirmInProgress}
                actions={() => (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate('/account')
                            }}
                        >
                            Modifier mon adresse e-mail
                        </Button>
                    </>
                )}
            >
                {(inProgress) => (
                    <Resend
                        disabled={inProgress || resendCode}
                        loading={restaurantOwnerResendInProgress}
                        onResend={onResend}
                    />
                )}
            </RestaurantOwnerConfirmForm>
        </ContainerLeft>
    )
}

export default withAuthenticateLayout(Confirm)
