import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            minHeight: 300,
            position: 'relative',
        },
    }),
)
