import React, { ComponentType } from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { createRootReducer } from 'core/store/reducers'
import { createMiddleware } from 'core/store/middleware'
import { History } from 'history'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import createSagaMiddleware from 'redux-saga'
import { SagaMiddleware } from '@redux-saga/core'
import { createReduxHistoryContext } from 'redux-first-history'

interface InputProps {
    history: History
}

interface OutputProps {
    history: History
    sagaMiddleware: SagaMiddleware
}

const withStoreProvider = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithStoreProvider: React.FC<InputProps> = ({ history, ...props }) => {
        const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
            history,
        })

        const rootReducer = createRootReducer(routerReducer)
        const sagaMiddleware = createSagaMiddleware()
        const store = createStore(rootReducer, createMiddleware(routerMiddleware, sagaMiddleware))
        const historyWithRedux = createReduxHistory(store)
        return (
            <Provider store={store}>
                <Router history={historyWithRedux}>
                    <ComposedComponent
                        history={historyWithRedux}
                        sagaMiddleware={sagaMiddleware}
                        {...props}
                    />
                </Router>
            </Provider>
        )
    }
    return WithStoreProvider
}

export default withStoreProvider
