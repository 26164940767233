import SubmitButton from 'components/common/submitButton'
import React from 'react'
import { Form, FormSpy } from 'react-final-form'
import PasswordField from './components/passwordField'
import { Trans, useTranslation } from 'react-i18next'
import CodeField from './components/codeField'
import { useFormsStyles } from './forms.css'
import PasswordRules from './components/passwordRules'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: ForgottenPasswordConfirmFormState) => void
    initialValues: ForgottenPasswordConfirmFormState
    inProgress: boolean
}

export interface ForgottenPasswordConfirmFormState {
    password: string
    passwordConfirmation: string
    code: string
}

export interface ForgottenPasswordConfirmFormError {
    password?: string
    passwordConfirmation?: string
    code?: string
}

const ForgottenPasswordConfirmForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    initialValues,
    inProgress,
}) => {
    const formsClasses = useFormsStyles()
    const { t } = useTranslation()

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: ForgottenPasswordConfirmFormState) => {
        const errors: ForgottenPasswordConfirmFormError = {}

        if (!values.password) {
            errors.password = t('form-validation.password')
        } else if (!strongPasswordRegex.test(values.password)) {
            errors.password = t('form-validation.passwordStrength')
        }

        if (
            (values.passwordConfirmation || values.password) &&
            values.password !== values.passwordConfirmation
        ) {
            errors.passwordConfirmation = t('form-validation.passwordConfirmationNotMatch')
        }
        if (!values.code || values.code.length !== 6) {
            errors.code = t('form-validation.code')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div>
                        <CodeField name="code" />
                        <PasswordField
                            margin="none"
                            name="password"
                            label={t('form.passwordNew')}
                            autoComplete="new-password"
                        />
                        <FormSpy subscription={{ values: true }}>
                            {(props) => <PasswordRules value={props.values.password} />}
                        </FormSpy>
                        <PasswordField
                            name="passwordConfirmation"
                            label={t('form.passwordConfirmation')}
                            autoComplete="new-password"
                        />
                    </div>
                    <div className={formsClasses.actionsInline}>
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={submitting || inProgress}
                            className={formsClasses.submit}
                        >
                            <Trans i18nKey="actions.validate" />
                        </SubmitButton>
                    </div>
                </form>
            )}
        </Form>
    )
}

export default ForgottenPasswordConfirmForm
