import { Link, Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './cgu.css'

const Cgu: React.FC = () => {
    const classes = useStyles()
    return (
        <Typography className={classes.cguLink}>
            <Trans
                i18nKey="home.cgu"
                components={[<Link href="https://www.suggpro.com/condition-generales-utilisation/" />]}
            />
        </Typography>
    )
}

export default Cgu
