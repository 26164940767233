import { AnyAbility } from '@casl/ability'
import { Can } from '@casl/react'
import { Button, ListSubheader, Typography } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import { default as MuiLink } from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AddIcon from '@material-ui/icons/Add'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import BusinessIcon from '@material-ui/icons/Business'
import EditIcon from '@material-ui/icons/Edit'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LanguageIcon from '@material-ui/icons/Language'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { Skeleton } from '@material-ui/lab'
import { EstablishmentModel, RestaurantOwnerModel } from '@sugg-gestion/react-onvaauresto'
import remplisVert from 'assets/main/remplisVert.png'
import Logo from 'components/common/logo'
import BookingIcon from 'components/icons/bookingIcon'
import FacebookRoundIcon from 'components/icons/facebookRoundIcon'
import GoogleMyBusinessIcon from 'components/icons/googleMyBusinessIcon'
import InstagramRoundIcon from 'components/icons/instagramRoundIcon'
import PadLockIcon from 'components/icons/padLockIcon'
import ShareIcon from 'components/icons/shareIcon'
import StatisticsIcon from 'components/icons/statisticsIcon'
import TripAdvisorIcon from 'components/icons/tripAdvisorIcon'
import TwitterRoundIcon from 'components/icons/twitterRoundIcon'
import UpArrowCircleIcon from 'components/icons/upArrowCircleIcon'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useStyles } from './authenticateDrawer.css'

interface Props {
    restaurantOwner: RestaurantOwnerModel
    signOut: () => void
    establishment?: EstablishmentModel
    ability: AnyAbility
}

const AuthenticateDrawer: React.FC<Props> = ({ signOut, restaurantOwner, establishment, ability }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const location = useLocation()

    return (
        <div>
            <Hidden xsDown implementation="css">
                <div className={classes.toolbar}>
                    <Logo className={classes.logoContainer} spacing={0} />
                </div>
            </Hidden>
            <div className={classes.content}>
                <div className={classes.titleContainer}>
                    {restaurantOwner.establishment!! ? (
                        establishment?.name ? (
                            <Typography variant="h1" color="textSecondary">
                                {establishment.name}
                            </Typography>
                        ) : (
                            <Skeleton variant="rect" width="100%">
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="drawer.title" />
                                </Typography>
                            </Skeleton>
                        )
                    ) : (
                        <Typography variant="h1" color="textSecondary">
                            <Trans i18nKey="drawer.title" />
                        </Typography>
                    )}
                    <Typography color="textSecondary">
                        <Trans
                            i18nKey="drawer.welcome"
                            values={{
                                name: restaurantOwner.givenName,
                            }}
                            components={[<Typography component="span" color="primary" />]}
                        />
                    </Typography>
                </div>
                <div className={classes.action}>
                    <Can I="create" a="establishment" ability={ability}>
                        <Button
                            component={Link}
                            to="/establishment"
                            variant="contained"
                            color="primary"
                            endIcon={
                                location.pathname === '/establishment' ? <NavigateNextIcon /> : <AddIcon />
                            }
                        >
                            <Trans i18nKey="actions.addEstablishment" />
                        </Button>
                    </Can>
                    <Can I="update" a="establishment" ability={ability}>
                        <Button
                            component={Link}
                            to="/establishment/menu/replace"
                            variant="contained"
                            color="primary"
                            endIcon={
                                location.pathname !== '/establishment/menu/replace' ? (
                                    <NavigateNextIcon />
                                ) : (
                                    <EditIcon />
                                )
                            }
                        >
                            <Trans i18nKey="actions.updateEstablishment" />
                        </Button>
                    </Can>
                </div>
                <Can I="update" a="establishment" ability={ability}>
                    <List
                        component="nav"
                        subheader={
                            <ListSubheader
                                component={Typography}
                                classes={{
                                    root: classes.listTitle,
                                }}
                            >
                                <Trans i18nKey="drawer.manage" />
                            </ListSubheader>
                        }
                    >
                        <ListItem
                            button
                            to="/establishment/details"
                            component={Link}
                            className={classes.listItem}
                            selected={location.pathname === '/establishment/details'}
                        >
                            <ListItemIcon>
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('form.establishment')} />
                        </ListItem>
                        <ListItem
                            button
                            to="/establishment/address"
                            component={Link}
                            className={classes.listItem}
                            selected={location.pathname === '/establishment/address'}
                        >
                            <ListItemIcon>
                                <LocationOnIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('form.address')} />
                        </ListItem>
                        <ListItem
                            button
                            to="/establishment/menu"
                            component={Link}
                            className={classes.listItem}
                            selected={location.pathname === '/establishment/menu'}
                        >
                            <ListItemIcon>
                                <PhotoCameraIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('form.photo')} />
                        </ListItem>
                        <ListItem
                            button
                            href="https://resto.suggpro.com/comment-passer-a-suggpro"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <ShareIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.suggproTitle,
                                    secondary: classes.suggproActions,
                                }}
                                primary={t('common.suggProShare')}
                                secondary={
                                    <>
                                        <FacebookRoundIcon color="action" />
                                        <InstagramRoundIcon color="action" />
                                        <GoogleMyBusinessIcon color="action" />
                                        <TripAdvisorIcon color="action" />
                                        <TwitterRoundIcon color="action" />
                                    </>
                                }
                            />
                            <ListItemIcon>
                                <PadLockIcon color="inherit" className={classes.lockIcon} />
                                <UpArrowCircleIcon color="primary" />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem
                            button
                            href="https://resto.suggpro.com/comment-passer-a-suggpro"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <AnnouncementIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('common.news')} />
                            <ListItemIcon>
                                <PadLockIcon color="inherit" className={classes.lockIcon} />
                                <UpArrowCircleIcon color="primary" />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem
                            button
                            href="https://resto.suggpro.com/comment-passer-a-suggpro"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <MenuBookIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('common.menu')} />
                            <ListItemIcon>
                                <PadLockIcon color="inherit" className={classes.lockIcon} />
                                <UpArrowCircleIcon color="primary" />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem
                            button
                            href="https://resto.suggpro.com/comment-passer-a-suggpro"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('common.externalLinks')} />
                            <ListItemIcon>
                                <PadLockIcon color="inherit" className={classes.lockIcon} />
                                <UpArrowCircleIcon color="primary" />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem
                            button
                            href="https://resto.suggpro.com/comment-passer-a-suggpro"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <BookingIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('common.booking')} />
                            <ListItemIcon>
                                <PadLockIcon color="inherit" className={classes.lockIcon} />
                                <UpArrowCircleIcon color="primary" />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem
                            button
                            href="https://resto.suggpro.com/comment-passer-a-suggpro"
                            component={MuiLink}
                            className={classes.listItem}
                            target="_blank"
                        >
                            <ListItemIcon>
                                <StatisticsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('common.statistics')} />
                            <ListItemIcon>
                                <PadLockIcon color="inherit" className={classes.lockIcon} />
                                <UpArrowCircleIcon color="primary" />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem
                            button
                            to="/establishment/details"
                            component={Link}
                            className={classes.listItem}
                        >
                            <ListItemIcon>
                                <img src={remplisVert} alt="RemplisVert" className={classes.remplisVert} />
                            </ListItemIcon>
                            <ListItemText primary={t('common.remplisVert')} />
                        </ListItem>
                    </List>
                </Can>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader
                            component={Typography}
                            classes={{
                                root: classes.listTitle,
                            }}
                        >
                            Paramètres
                        </ListSubheader>
                    }
                >
                    <Can I="confirm" a="account" ability={ability}>
                        <ListItem
                            button
                            to="/account/confirm"
                            component={Link}
                            className={classes.listItem}
                            selected={location.pathname === '/account/confirm'}
                        >
                            <ListItemIcon>
                                <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('drawer.confirm')} />
                        </ListItem>
                    </Can>
                    <ListItem
                        button
                        to="/account"
                        component={Link}
                        className={classes.listItem}
                        selected={location.pathname === '/account'}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.update')} />
                    </ListItem>
                    <ListItem button className={classes.listItem} onClick={signOut}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.logOut')} />
                    </ListItem>
                </List>
            </div>
        </div>
    )
}

export default AuthenticateDrawer
