import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        forgottenPassword: {
            'display': 'flex',
            'justifyContent': 'flex-end',
            '& .MuiButton-root': {
                textTransform: 'none',
                fontSize: '1rem',
                textDecoration: 'underline',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '0.8rem',
                },
            },
        },
    }),
)
