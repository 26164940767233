import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            width: 35,
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        imageIcon: {
            margin: 0,
            width: 30,
            height: 30,
        },
        imageIconLarge: {
            margin: 0,
            width: 40,
            height: 40,
        },
        shareIcon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: theme.spacing(),
        },
        titleContainer: {
            flexGrow: 1,
        },
        facebook: {
            color: theme.socialColors.facebook,
        },
        instagram: {
            color: theme.socialColors.instagram,
        },
        twitter: {
            color: theme.socialColors.twitter,
        },
        suggProList: {
            position: 'relative',
        },
        suggProOverlay: {
            position: 'absolute',
            inset: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#e5e5e599',
            zIndex: 100,
        },
        title: {
            textDecoration: 'underline',
        },
        button: {
            zIndex: 200,
            width: 235,
            maxWidth: '100%',
            [theme.breakpoints.down('md')]: {
                'width': 200,
                '& .MuiButton-startIcon': {
                    display: 'none',
                },
            },
        },
        item: {
            padding: theme.spacing(1, 2),
            flexWrap: 'wrap',
        },
        itemTitle: {
            flexWrap: 'nowrap',
        },
        largeIcon: {
            '& .MuiButton-startIcon.MuiButton-iconSizeMedium > *:first-child': {
                fontSize: '1.8rem',
            },
        },
    }),
)
