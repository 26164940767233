import { Button, FormHelperText, Grid, Typography } from '@material-ui/core'
import { AddressModel, LocationModel } from '@sugg-gestion/react-onvaauresto'
import SubmitButton from 'components/common/submitButton'
import { useNominatim } from 'core/services/address/useNominatim'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Form, FormSpy, FormSpyRenderProps } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import CountryField from './components/countryField'
import LocationField from './components/locationField'
import TextField from './components/textField'
import { useStyles } from './establishmentForm.css'
import { useFormsStyles } from './forms.css'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EstablishmentAddressFormState) => void
    initialValues: EstablishmentAddressFormState
    inProgress: boolean
    onBack?: () => void
    isMobile: boolean
}

export interface EstablishmentAddressFormState {
    address: AddressModel
    location: LocationModel
}

export interface EstablishmentAddressFormInternalState {
    address_line1: string
    address_line2?: string
    address_zipCode: string
    address_city: string
    address_country: string
    location: LocationModel
}

export interface EstablishmentAddressFormInternalError {
    address_line1?: string
    address_line2?: string
    address_zipCode?: string
    address_city?: string
    address_country?: string
    location?: string
}

const EstablishmentAddressForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
        errors: true,
    },
    onSubmit,
    initialValues,
    inProgress,
    onBack,
    isMobile,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const formsClasses = useFormsStyles()
    const { geocode } = useNominatim()
    const { enqueueSnackbar } = useSnackbar()
    const [localizeMyAddressInProgress, setLocalizeMyAddressInProgress] = useState<boolean>(false)

    const initialValuesInternal: EstablishmentAddressFormInternalState = {
        address_line1: initialValues.address.line1,
        address_line2: initialValues.address.line2,
        address_zipCode: initialValues.address.zipCode,
        address_city: initialValues.address.city,
        address_country: initialValues.address.country,
        location: initialValues.location,
    }

    const submit = (values: EstablishmentAddressFormInternalState) => {
        return onSubmit({
            address: {
                line1: values.address_line1,
                line2: values.address_line2,
                zipCode: values.address_zipCode,
                city: values.address_city,
                country: values.address_country,
            },
            location: values.location,
        })
    }

    const validate = (values: EstablishmentAddressFormInternalState) => {
        const errors: EstablishmentAddressFormInternalError = {}

        if (
            !values.location ||
            !values.location.latitude ||
            !values.location.longitude ||
            (values.location.latitude === 0 && values.location.longitude === 0) ||
            (values.location.latitude === 46.7111 && values.location.longitude === 1.7191)
        ) {
            errors.location = t('form-validation.location')
        }

        if (!values.address_line1 || values.address_line1 === '') {
            errors.address_line1 = t('form-validation.addressStreet1')
        }

        if (!values.address_zipCode || values.address_zipCode === '') {
            errors.address_zipCode = t('form-validation.addressZipCode')
        }

        if (!values.address_city || values.address_city === '') {
            errors.address_city = t('form-validation.addressCity')
        }

        if (!values.address_country || values.address_country === '') {
            errors.address_country = t('form-validation.addressCountry')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValuesInternal}
            subscription={subscription}
            // debug={console.log}
            onSubmit={submit}
            validate={validate}
            mutators={{
                setPosition: (args, state, tools) => {
                    const position = args[0] as LocationModel
                    tools.changeValue(state, 'location', () => position)
                },
            }}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting, form: { mutators }, errors }) => {
                const submit = (event: any) => {
                    handleSubmit(event)
                    // location is fetched during submit: so, we don't know if error is real
                    if (errors) {
                        delete errors.location
                    }
                    if (!_.isEmpty(errors)) {
                        enqueueSnackbar(t('error.validation'), {
                            variant: 'error',
                        })
                    }
                }
                const localizeAddress = (props: FormSpyRenderProps) => {
                    setLocalizeMyAddressInProgress(true)
                    let street = undefined
                    let city = undefined
                    let country = undefined
                    if (props.values.address_line1) {
                        street = props.values.address_line1
                    }
                    if (street && props.values.address_line2) {
                        street += props.values.address_line2
                    }
                    if (props.values.address_city) {
                        city = props.values.address_city
                    }
                    if (props.values.address_country) {
                        country = t('countries.' + props.values.address_country)
                    }
                    if (!(street && city)) {
                        setLocalizeMyAddressInProgress(false)
                        enqueueSnackbar(t('form.localizationFailed'), {
                            variant: 'info',
                        })
                        return new Promise<void>((resolve) => resolve())
                    }
                    return geocode(street, city, country)
                        .then((result) => {
                            setLocalizeMyAddressInProgress(false)
                            if (result[0]) {
                                const position: LocationModel = {
                                    longitude: parseFloat(result[0].lon),
                                    latitude: parseFloat(result[0].lat),
                                }
                                mutators.setPosition(position)
                            } else {
                                enqueueSnackbar(t('form.localizationFailed'), {
                                    variant: 'info',
                                })
                            }
                        })
                        .catch((_) => {
                            enqueueSnackbar(t('form.localizationFailed'), {
                                variant: 'info',
                            })
                            setLocalizeMyAddressInProgress(false)
                        })
                }
                return (
                    <form noValidate>
                        <Grid container spacing={8}>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="form.address" />
                                </Typography>
                                {/* Address */}
                                <div>
                                    <TextField
                                        name="address_line1"
                                        label={t('form.addressStreet1')}
                                        autoComplete="billing address-line1"
                                        required
                                    />
                                    <TextField
                                        name="address_line2"
                                        label={t('form.addressStreet2')}
                                        autoComplete="billing address-line2"
                                    />
                                    <TextField
                                        name="address_zipCode"
                                        label={t('form.addressZipCode')}
                                        autoComplete="billing postal-code"
                                        required
                                    />
                                    <TextField
                                        name="address_city"
                                        label={t('form.addressCity')}
                                        autoComplete="billing address-level2"
                                        required
                                    />
                                    <CountryField
                                        name="address_country"
                                        label={t('form.addressCountry')}
                                        required
                                    />
                                </div>
                                <div className={classes.actions}>
                                    <FormSpy
                                        subscription={{
                                            values: true,
                                        }}
                                    >
                                        {(props) => (
                                            <SubmitButton
                                                variant="contained"
                                                color="primary"
                                                loading={localizeMyAddressInProgress}
                                                disabled={
                                                    !(
                                                        props.values.address_line1 &&
                                                        props.values.address_zipCode &&
                                                        props.values.address_city
                                                    ) ||
                                                    submitting ||
                                                    inProgress
                                                }
                                                onClick={() => localizeAddress(props)}
                                                className={formsClasses.submit}
                                            >
                                                <Trans i18nKey="form.localizeAddress" />
                                            </SubmitButton>
                                        )}
                                    </FormSpy>
                                </div>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Typography variant="h1" color="textSecondary">
                                    <Trans i18nKey="form.location" />
                                </Typography>
                                {/* Map */}
                                <LocationField name="location" update={0} />
                                <FormHelperText className={formsClasses.helper}>
                                    <Trans i18nKey="form.localizeAddressHelper" />
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <div className={formsClasses.actions}>
                            {onBack!! && (
                                <Button variant="contained" onClick={onBack}>
                                    <Trans i18nKey="actions.back" />
                                </Button>
                            )}
                            <FormSpy
                                subscription={{
                                    values: true,
                                }}
                            >
                                {(props) => (
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        loading={submitting || inProgress}
                                        disabled={localizeMyAddressInProgress}
                                        className={formsClasses.submit}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            const { values } = props
                                            if (
                                                values.address_line1 &&
                                                values.address_zipCode &&
                                                values.address_city &&
                                                (!values.location ||
                                                    !values.location.latitude ||
                                                    !values.location.longitude ||
                                                    (values.location.latitude === 0 &&
                                                        values.location.longitude === 0) ||
                                                    (values.location.latitude === 46.7111 &&
                                                        values.location.longitude === 1.7191))
                                            ) {
                                                return localizeAddress(props).then(() => submit(event))
                                            } else {
                                                return submit(event)
                                            }
                                        }}
                                    >
                                        <Trans i18nKey="actions.validate" />
                                    </SubmitButton>
                                )}
                            </FormSpy>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}

export default EstablishmentAddressForm
