import React from 'react'
import { Trans } from 'react-i18next'
import { MobileStepper, Typography } from '@material-ui/core'
import { useStyles } from './step.css'

interface Props {
    step: number
    steps: number
}

const Step: React.FC<Props> = ({ step, steps }) => {
    const classes = useStyles()

    const displayTitle = () => {
        switch (step) {
            case steps - 2:
                return <Trans i18nKey="establishment.confirmation" />
            case steps - 1:
                return <Trans i18nKey="establishment.end" />
            default:
                return <Trans i18nKey="establishment.step" values={{ step: step + 1, steps: steps - 1 }} />
        }
    }
    return (
        <div>
            <Typography color="textSecondary" className={classes.title}>
                {displayTitle()}
            </Typography>
            <MobileStepper
                variant="progress"
                steps={steps}
                position="static"
                activeStep={step}
                nextButton={null}
                backButton={null}
                className={classes.stepper}
            />
        </div>
    )
}

export default Step
