import React from 'react'
import { Field } from 'react-final-form'
import ReactCodeInput from 'react-code-input'
import useTheme from '@material-ui/core/styles/useTheme'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        code: {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'flexDirection': 'column',
            '& > p': {
                width: '100%',
                maxWidth: 400,
            },
        },
        codeInputs: {
            'alignItems': 'center',
            'justifyContent': 'space-between',
            'maxWidth': 400,
            'margin': theme.spacing(2, 0),
            '& input': {
                'color': theme.palette.text.primary,
                'cursor': 'text',
                'width': '12%',
                'margin': theme.spacing(0, 1),
                'fontSize': '1.3rem',
                'display': 'inline-flex',
                'alignItems': 'center',
                'fontFamily': theme.typography.fontFamily,
                'lineHeight': '1.1875em',
                'padding': '18.5px 14px',
                'borderRadius': theme.spacing(0.5),
                'borderColor': 'rgba(0, 0, 0, 0.23)',
                'borderStyle': 'solid',
                'borderWidth': 1,
                'textAlign': 'center',
                '-moz-appearance': 'textfield',
                'transition':
                    'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                '@media screen and (max-width: 400px)': {
                    padding: '14px 8px',
                    margin: theme.spacing(0, 0.5),
                    width: '13%',
                },
            },
            '& input:focus, & input:focus:hover': {
                outline: 0,
                borderColor: theme.palette.primary.main,
                boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
            },
            '& input:hover': {
                borderColor: theme.palette.text.primary,
            },
            '@media screen and (max-width: 400px)': {
                margin: theme.spacing(1, 0),
            },
        },
    }),
)

interface Props {
    name: string
    onChange?: (value: string) => void
}

const CodeField: React.FC<Props> = ({ name, onChange: onExternalChange }) => {
    const theme = useTheme()
    const classes = useStyles()
    return (
        <Field name={name}>
            {({ input: { name, onChange, value }, meta }) => {
                const hasError =
                    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
                return (
                    <div className={classes.code}>
                        <ReactCodeInput
                            name={name}
                            type="number"
                            inputMode="numeric"
                            fields={6}
                            className={classes.codeInputs}
                            onChange={(value: string) => {
                                onChange(value)
                                if (onExternalChange) {
                                    onExternalChange(value)
                                }
                            }}
                            inputStyleInvalid={{
                                color: theme.palette.error.main,
                                borderColor: theme.palette.error.main,
                            }}
                            isValid={!hasError}
                        />
                        {meta.error && meta.touched && (
                            <FormHelperText error={hasError}>{meta.error}</FormHelperText>
                        )}
                    </div>
                )
            }}
        </Field>
    )
}

export default CodeField
