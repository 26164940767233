import { CardContent, Divider } from '@material-ui/core'
import { Card } from '@material-ui/core'
import React from 'react'
import { useStyles } from './homeView.css'
import Intro from './intro'

const HomeView: React.FC = ({ children }) => {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <CardContent className={classes.container}>
                <Intro className={classes.intro} />
                <Divider variant="middle" />
                <div className={classes.content}>{children}</div>
            </CardContent>
        </Card>
    )
}

export default HomeView
