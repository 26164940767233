import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: 700,
            paddingBottom: theme.spacing(2),
        },
        mainLogo: {
            paddingBottom: '10%',
            [theme.breakpoints.down('xs')]: {
                paddingBottom: 0,
            },
        },
        assetsLogo: {
            'paddingTop': '10%',
            'display': 'inline-flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'flexDirection': 'column',
            '& > img': {
                width: '100%',
            },
            [theme.breakpoints.down('xs')]: {
                'paddingTop': 0,
                '& > img': {
                    maxHeight: 140,
                    width: 'inherit',
                },
            },
        },
    }),
)
