import React from 'react'
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import CloseButton from '../common/closeButton'
import { useStyles } from './editDialog.css'

interface Props {
    onClose: () => void
}

const EditDialog: React.FC<Props> = ({ children, onClose }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Dialog
            open={true}
            onClose={onClose}
            fullScreen={isMobile}
            classes={{
                paper: classes.paper,
            }}
            scroll="body"
        >
            <DialogContent>
                <CloseButton handleClose={onClose} fullScreen={isMobile} />
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
